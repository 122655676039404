<div class="c-activityEntryInfo">
  <mat-icon class="c-activityEntryInfo__icon" [class.active]="!!activity.note" [matMenuTriggerFor]="activityInfoMenu">
    info
  </mat-icon>

  <mat-menu #activityInfoMenu="matMenu" class="short">
    <div class="c-activityEntryInfo__content">
      <div class="c-activityEntryInfo__contentHeader">
        <div class="c-activityEntryInfo__title">
          <p class="c-activityListEntry__prioritiesItem {{activity.priority.description}}">
            <span class="c-activityListEntry__prioritiesItemDot"></span>
          </p>
          <span class="c-activityEntryInfo__titleText">{{activity.description}}</span>
        </div>
        <div class="flex items-center ml-2.5">
          <mat-icon class="c-activityEntryInfo__contentHeaderBtn" (click)="openMoreInfo()">edit</mat-icon>
          <mat-icon class="c-activityEntryInfo__contentHeaderBtn">close</mat-icon>
        </div>
      </div>
      <div class="c-activityEntryInfo__contentBody" (click)="$event.stopPropagation()">
        <div class="flex items-center">
          <p *ngIf="!!activity.fixedStart" class="c-activityEntryInfo__subtitle mr-3">{{'Fixed start' | translate}}
            : {{activity.fixedStart | dateTimeFormat}},</p>
          <p class="c-activityEntryInfo__subtitle mr-3">{{'Time' | translate}}: {{activity.actStart | dateTimeFormat}}
            - {{activity.actEnd | dateTimeFormat}},</p>
          <p class="c-activityEntryInfo__subtitle">{{'Duration' | translate}}
            : {{activity.duration}}{{'min' | translate}}</p>
        </div>
        <div *ngIf="!!activity.note" class="mt-5">
          <p class="c-activityEntryInfo__subtitle mb-2.5">{{'Description:' | translate}}</p>
          <div [innerHTML]="note"></div>
        </div>
      </div>
    </div>
  </mat-menu>
</div>
