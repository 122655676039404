import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Appointment } from 'src/app/core/models/interfaces';
import { DataproviderService } from 'src/app/core/services/dataprovider.service';

@Injectable({
  providedIn: 'root'
})
export class CaldavService {

  constructor(
    private http: HttpClient,
    private dataProviderService: DataproviderService,
    ) { }

  public getCaldavEvents(date: String, timezone: String): Observable<any> {
    let myparams = new HttpParams();

    myparams = myparams.append('date', encodeURIComponent(date.toString()));
    myparams = myparams.append('tz', encodeURIComponent(timezone.toString()));

    console.log(myparams.toString());

    return this.http.get('/caldav', {params: myparams}).pipe(map((result: any) => {
      const activities = Array.from(this.dataProviderService.activities.values());
      let items = [];
            
      if (result.length) {        
        result.forEach((item) => {
          try {
            const appointment: Appointment = {
              extId: item.extId,
              extLink: item.extLink,
              summary: item.summary,
              startTime: DateTime.fromISO(item.startTime),
              endTime: DateTime.fromISO(item.endTime),
              allDay: item.allDay,
              provider: 'caldav',
              planned: activities.some((act) => act.appointment ? act.appointment.extId === item.extId : false),
            }
            appointment.startTime = this.setDate(appointment.startTime, date);
            appointment.endTime = this.setDate(appointment.endTime, date);
            items.push(appointment);
          } catch (message) {
            console.log(message);
          }
          
        });
      }
      return items;
    })
    );
  }

  /**
   * Sets date on an existing DateTime object
   * @param dateTime The object to change date for
   * @param date The date to set
   */
  private setDate(dateTime: DateTime, date: String): DateTime {
    let dateObj = DateTime.fromISO(date.toString());
    dateTime = dateTime.set({day: dateObj.day, month: dateObj.month, year: dateObj.year});
    return dateTime;
  }
}
