import {
  Component,
  Input, OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, from, interval } from 'rxjs';
import { filter, switchMap, tap, mergeMap } from 'rxjs/operators';
import { Appointment } from 'src/app/core/models/interfaces';
import { AppSettingsService } from 'src/app/core/services/app-settings.service';
import { GraphqlService } from 'src/app/core/services/graphql.service';
import { Calendar, GoogleCalendarService } from 'src/app/modules/google/services/google-calendar.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataproviderService } from '../../../../core/services/dataprovider.service';
import { MsalService } from '@azure/msal-angular';
import { ExchangeOnlineCalendarService } from '../../../microsoft/services/exchange-online-calendar.service';
import { GoogleSigninService } from '../../../google/services/google-signin.service';
import { CaldavService } from 'src/app/modules/caldav/services/caldav.service';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from '../../../../core/components/objects/spinner/spinner.component';
import { RouterLink } from '@angular/router';
import { AppButtonComponent } from '../../../../core/components/app-button/app-button.component';
import { AppointmentListEntryComponent } from '../appointment-list-entry/appointment-list-entry.component';
import { NgIf, NgFor } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-appointment-list',
    templateUrl: './appointment-list.component.html',
    styleUrls: ['./appointment-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        AppointmentListEntryComponent,
        AppButtonComponent,
        RouterLink,
        SpinnerComponent,
        TranslateModule,
    ],
})
export class AppointmentListComponent implements OnInit {
  _date: string;
  @Input() get date(): string {
    return this._date;
  }

  set date(date: string) {
    this._date = date;
    this.setForAllAppointmentsNotLoaded();
    this.getAllAppointments();
  }

  appointments: Appointment[] = [];
  appointments$ = new BehaviorSubject<Appointment[]>(this.appointments);

  integrations = {
    google: {
      enabled: false,
      loaded: false,
    },
    ews: {
      enabled: false,
      loaded: false,
    },
    exchangeOnline: {
      enabled: false,
      loaded: false,
    },
    caldav: {
      enabled: false,
      loaded: false,
    },
  };

  constructor(
    private googleCalendarService: GoogleCalendarService,
    private graphqlService: GraphqlService,
    private appSettingsService: AppSettingsService,
    private googleSigninService: GoogleSigninService,
    private dataProviderService: DataproviderService,
    private msalService: MsalService,
    private exchangeOnlineCalendarService: ExchangeOnlineCalendarService,
    private caldavService: CaldavService,
  ) {
  }

  ngOnInit() {
    this.appointments$.pipe(untilDestroyed(this))
      .subscribe((appointments) => {
        // @ts-ignore
        this.appointments = appointments.sort((a, b) => a.startTime.ts - b.startTime.ts);
      });

    const fiveMinutes = 300000;
    interval(fiveMinutes)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.getAllAppointments();
      });
  }

  setForAllAppointmentsNotLoaded() {
    this.integrations.google.loaded = false;
    this.integrations.ews.loaded = false;
    this.integrations.exchangeOnline.loaded = false;
  }

  get getAllAppointmentsLoaded(): boolean {
    return this.integrations.ews.loaded && this.integrations.exchangeOnline.loaded && this.integrations.google.loaded;
  }

  get someAppointmentsIntegrationEnabled(): boolean {
    return this.integrations.ews.enabled || this.integrations.exchangeOnline.enabled || this.integrations.google.enabled;
  }

  getGoogleCalAppointments() {
    this.appSettingsService.isGoogleEnabled$().pipe(
      filter((enabled) => {
        this.integrations.google.loaded = true;
        return !!enabled;
      }),
      switchMap(() => this.appSettingsService.isGoogleCalEnabled$()),
      filter((enabled) => AppointmentListComponent.enablingIntegration(this.integrations.google, enabled)),
      switchMap(async () => this.googleCalendarService.prepareAllGoogleCalendars()),
      switchMap((res) => this.googleCalendarService.getGoogleCalendarList()), // get the list of google calendars
      switchMap((calendars) => from(calendars)),
      filter((calendar: Calendar) => calendar.enabled),
      untilDestroyed(this),
    )
    .subscribe({
      next: (calendar) => {
        this.googleCalendarService.getEventsForDate(calendar.id, this._date).then((googleEvents) => {
          this.integrations.google.loaded = true;
          this.appointments$.next([...this.appointments$.value, ...googleEvents]);
        });
      },
      error: () => this.integrations.google.loaded = true,
    });
  }

  getEwsCalAppointments() {
    this.appSettingsService.isEwsCalEnabled$().pipe(
      filter((enabled) => AppointmentListComponent.enablingIntegration(this.integrations.ews, enabled)),
      switchMap(() => this.graphqlService.getEwsAppointments(this._date, DateTime.now().zone.formatOffset(0, 'short'))),
      untilDestroyed(this),
    ).subscribe((ewsEvents) => {
      this.integrations.ews.loaded = true;
      this.appointments$.next([...this.appointments$.value, ...ewsEvents]);
    }, () => {
      this.integrations.ews.loaded = true;
    });
  }

  getExchangeOnlineCalAppointments() {
    this.appSettingsService.isExchangeOnlineCalEnabled$().pipe(
      filter((enabled) => AppointmentListComponent.enablingIntegration(this.integrations.exchangeOnline, enabled)),
      switchMap(() => {
        const account = this.msalService.instance.getAllAccounts()[0];
        if (account) {
          const accessTokenRequest = {
            scopes: ['profile'],
            account: account,
          };
          return this.msalService.acquireTokenSilent(accessTokenRequest);
        } else {
          return this.msalService.loginPopup();
        }
      }),
      tap((res) => this.exchangeOnlineCalendarService.setExchangeOnlineJwtData(res)),
      switchMap(() => this.exchangeOnlineCalendarService.getListCalendars()),
      switchMap((calendars) => from(calendars)),
      filter((calendar) => calendar.enabled),
      mergeMap((calendar) => this.exchangeOnlineCalendarService.getListEvents(calendar.id, this._date)),
      untilDestroyed(this),
    ).subscribe((microsoftEvents) => {
      this.integrations.exchangeOnline.loaded = true;
      this.appointments$.next([...this.appointments$.value, ...microsoftEvents]);
    }, () => {
      this.integrations.exchangeOnline.loaded = true;
    });
  }

  getCalDavAppointments() {
    this.appSettingsService.isCaldavEnabled$().pipe(
      filter((enabled) => AppointmentListComponent.enablingIntegration(this.integrations.ews, enabled)),
      switchMap(() => this.caldavService.getCaldavEvents(
        this._date, DateTime.now().zone.formatOffset(0, 'short'),
      )),
      untilDestroyed(this),
    ).subscribe({
        next: (caldavEvents) => {
          this.integrations.caldav.loaded = true;
          this.appointments$.next([...this.appointments$.value, ...caldavEvents]);
        },
        error: () => this.integrations.caldav.loaded = true,
      });
  }

  getAllAppointments() {
    this.appointments$.next([]);
    this.getGoogleCalAppointments();
    this.getEwsCalAppointments();
    this.getExchangeOnlineCalAppointments();
    this.getCalDavAppointments();
  }

  private static enablingIntegration(integration, enabled: boolean) {
    integration.enabled = enabled;
    if (!enabled) {
      integration.loaded = true;
    }
    return enabled;
  }
}
