<div class="c-dayEditor" cdkDropListGroup>
  <day-editor-header (onSetDateEvent)="setDate($event)"></day-editor-header>

  <day-editor-body
    [selectedDate]="selectedDate"
    [date]="date"
    [phases]="phases()"
    [priorities]="priorities()">
  </day-editor-body>
</div>

<onboarding-tooltips [onboardingTooltips]="dayEditorOnboardingTooltips"></onboarding-tooltips>
