import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../../../services/theme.service';
import { CookieService } from 'ngx-cookie';
import { AsyncPipe } from '@angular/common';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
    selector: 'theme-switcher',
    templateUrl: './theme-switcher.component.html',
    styleUrls: ['./theme-switcher.component.scss'],
    standalone: true,
    imports: [MatSlideToggle, AsyncPipe],
})
export class ThemeSwitcherComponent implements OnInit {
  darkModeEnabled$ = this.themeService.darkModeEnabled$;

  constructor(
    private themeService: ThemeService,
    private cookie: CookieService,
  ) { }

  ngOnInit(): void {
  }


  switchTheme(): void {
    const theme = this.cookie.get('dark') !== 'true';
    this.themeService.toggleDarkTheme(theme);
  }
}
