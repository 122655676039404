import { AppSettingsService } from '../core/services/app-settings.service';
import { AuthService } from '../modules/auth/services/auth.service';
import { take, filter, switchMap } from 'rxjs/operators';
import { UserLocaleService } from '../shared/services/user-locale.service';
import { LanguageService } from '../shared/translates/language.service';

export function AppInitializer (
  authService: AuthService,
  appSettingsService: AppSettingsService,
  userLocaleService: UserLocaleService,
  languageService: LanguageService,
) {
  languageService.initUserLanguage();
  userLocaleService.setLocale(userLocaleService.locale);

  return () => authService.isLoggedIn$().pipe(
      filter((isLoggedIn) => isLoggedIn),
      take(1),
      switchMap(() => appSettingsService.initGlobalSettings()),
    ).subscribe();
}

