import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { DateTime } from 'luxon';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { Activity, Phase } from '../core/models/interfaces';
import { AppSettingsService } from '../core/services/app-settings.service';

@Injectable({providedIn: 'root'})
export class PhaseSorter {
  public sort(activities: Map<number, Activity>, phases: Phase[]) {
    phases.sort(PhaseSorter.compareFixedStartPhase);
    phases.forEach((phase) => this.sortPhase(phase, activities));
  }

  private sortPhase(phase: Phase, activities: Map<number, Activity>) {
    phase.activities = [];
    from(activities.values()).pipe(
      filter((activity) => phase.id === activity.phase?.id),
      tap((activity) => phase.activities.push(activity)),
    ).subscribe();
    phase.activities = PhaseSorter.sortActivities(phase);
  }

  /**
   * sorts the activities with in a phase based on the
   * previous and next activity ids - doubly linked list
   * and calculate start and end times
   * @param phase
   * @return array of activities
   */
  private static sortActivities(phase: Phase): Activity[] {
    const unsorted = phase.activities;
    const sorted: Activity[] = [];

    // finding the first activity in unsorted
    // based on the condition that there is no activity
    // in the list, which has the previousActivityId of the first element
    // and push it as first element to sorted
    PhaseSorter.pushAsFirstElementToSorted(sorted, unsorted);

    // now pushing the activities in order to
    // the sorted list
    PhaseSorter.pushingActivitiesInSortedList(sorted, unsorted);

    // now calculate the start and end times
    // based on the fixedStart time of the phase
    PhaseSorter.recalculateStartEndTime(sorted, phase.fixedStart);

    return sorted;
  }

  public static recalculateStartEndTime(activities: Activity[], fixedStart: DateTime): Activity[]{
    let durSum = 0;
    let tmpFixedStart = fixedStart;

    for (const act of activities) {
      if (act.fixedStart) {
        tmpFixedStart = act.fixedStart;
        durSum = 0;
      }
      act.actStart = tmpFixedStart.plus({minutes: durSum});
      durSum = durSum + +act.duration;
      act.actEnd = tmpFixedStart.plus({minutes: durSum});
    }

    // mark an activity if it is out of the phase's range or
    // intersects with the next activity
    PhaseSorter.checkForTimeFailures(activities, fixedStart);

    return activities;
  }

  private static pushAsFirstElementToSorted(sorted: Activity[], unsorted: Activity[]) {
    for (const act of unsorted) {
      let first = true;
      for (const actSearch of unsorted) {
        if (act.previousActivityId === actSearch.id) {
          first = false;
        }
      }
      if (first) {
        sorted.push(act);
      }
    }
  }

  private static pushingActivitiesInSortedList(sorted: Activity[], unsorted: Activity[]) {
    while (sorted.length < unsorted.length) {
      for (const act of sorted) {
        for (const actCheck of unsorted) {
          if (actCheck.id === act.nextActivityId) {
            sorted.push(actCheck);
          }
        }
      }
    }
  }

  /**
   * checks for time failures
   * 1) when startTime of an activity is less
   *    than the fixedStart of the phase
   * 2) when startTime of the activity is less
   *    than the endTime of the previous activity
   * @param activities
   * @param fixedStart
   */
  private static checkForTimeFailures(activities: Activity[], fixedStart: DateTime) {
    for (var i = 0; i < activities.length; i++){
      activities[i].timeFailure = false;

      if (activities[i].actStart < fixedStart){
        activities[i].timeFailure = true;
        continue;
      }

      if (i > 0 && activities[i].actStart < activities[i-1].actEnd){
        activities[i-1].timeFailure = true;
        activities[i].timeFailure = true;
      }
    }
  }

  private static compareFixedStartPhase(a: Phase, b: Phase) {
    if (a.fixedStart < b.fixedStart) {
      return -1;
    }
    if (a.fixedStart > b.fixedStart) {
      return 1;
    }
    return 0;
  }
}
