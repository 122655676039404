<div class="c-phasesList">
  <ng-container *ngIf="phases.length else phasesEmpty">
    <mat-accordion class="c-phasesList__accordion" #mat="matAccordion" multi>
      <mat-expansion-panel *ngFor="let phase of phases; let i = index"
                           [class.mat-expansion-panel-spacing]="false"
                           [expanded]="phase.isCurrentTime || (phase.activities.length > 1 && !isAllActivitiesDone(phase))"
                           #expansionPanel
                           hideToggle>
        <mat-expansion-panel-header class="disable-ripple">
          <div class="c-phasesListHeader"
                [ngClass]="{'done': isAllActivitiesDone(phase) && phase.activities.length > 1}"
                (click)="$event.stopPropagation()"
                (keydown)="$event.stopPropagation()">
            <div *ngIf="!editPhase(phase.id) else editName"
                 class="c-phasesListHeader__nameWrapper"
                 [ngClass]="{'isCurrentTime': phase.isCurrentTime}">
              <p class="c-phasesListHeader__name"
                 (click)="expansionPanel.expanded ? expansionPanel.close() : expansionPanel.open()">
                <mat-icon class="c-phasesListHeader__toggleIcon" [ngClass]="{'expanded': expansionPanel.expanded}" svgIcon="expand-more"></mat-icon> {{phase.name}}
              </p>
              <div class="c-phasesListHeader__separator"></div>
              <p class="c-phasesListHeader__fixedStart">{{phase.fixedStart | dateTimeFormat}}</p>
            </div>

            <ng-template #editName>
              <input type="text" [value]="phase.name" class="c-phasesListHeader__inputEdit" [(ngModel)]="phase.name"/>
              <div class="c-phasesListHeader__separator"></div>

              <div class="c-phasesListHeader__fixedStart edit">
                <p>{{phase.fixedStart | dateTimeFormat}}</p>
                <mat-icon svgIcon="schedule"
                          class="c-phasesListHeader__fixedStartIcon"
                          [ngxTimepicker]="picker"
                          [format]="+timeFormat"
                          [value]="phase.fixedStart | dateTimeFormat"></mat-icon>
                <ngx-material-timepicker disableAnimation="true"
                                         [minutesGap]="5"
                                         [cancelBtnTmpl]="cancelBtnTmpl"
                                         [confirmBtnTmpl]="confirmBtnTmpl"
                                         (timeSet)="fixedStartChanged(phase, $event)"
                                         [theme]="timepickerTheme" #picker>
                </ngx-material-timepicker>
                <ng-template #cancelBtnTmpl>
                  <app-button btnStyle="outline">{{'Cancel' | translate}}</app-button>
                </ng-template>

                <ng-template #confirmBtnTmpl>
                  <app-button class="ml-3 block" style="min-width: 112px;">{{'Set' | translate}}</app-button>
                </ng-template>
              </div>

              <button data-testid="delete_phase_button" (click)="deletePhase(phase)" color="warn" mat-icon-button>
                <mat-icon svgIcon="delete"></mat-icon>
              </button>
              <button data-testid="cancel_phase_edit_button" (click)="cancelUpdatedPhase(phase)" class="ml-auto btn btn-outline btn-gray btn--auto">{{'Cancel' | translate}}</button>
              <button data-testid="save_phase_edit_button" (click)="saveUpdatedPhase(phase)" class="btn btn--auto ml-2.5">{{'Save' | translate}}</button>
            </ng-template>

            <ng-container *ngIf="!editPhase(phase.id)">
              <div class="c-phasesListHeader__separator"></div>
              <button class="c-phasesListHeader__btn c-phasesListHeader__btn--icon" (click)="enableEditPhase(phase)" mat-icon-button>
                <mat-icon>edit_outlined</mat-icon>
              </button>
              <div class="c-phasesListHeader__separator"></div>
              <div class="c-phasesListHeader__tasks">
                {{phase.activities.length}} {{'tasks' | translate}} <mat-icon *ngIf="isAllActivitiesDone(phase) && phase.activities.length > 1" class="c-phasesListHeader__tasksIcon">check</mat-icon>
              </div>

              <time-progress [phase]="phase" [phases]="phases" [date]="date" [nextPhase]="nextPhase(phase.id)"></time-progress>
            </ng-container>
          </div>
        </mat-expansion-panel-header>

        <div class="c-phasesListBody">
          <app-activity-list [phase]="phase"
                             [groupsWithTasks]="groupsWithTasks"
                             [activityStatuses]="activityStatuses"
                             [priorities]="priorities"
                             [date]="date"
                             [priorityPainterClass]="priorityPainterClass"
                             [dayEditor]="dayEditor"
                             [peopleContacts]="peopleContacts"
                             [googleContactsEnabled]="googleContactsEnabled">
          </app-activity-list>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>

  <ng-template #phasesEmpty>
    <ng-content data-phasesEmpty></ng-content>
  </ng-template>
</div>

<div class="c-priorityPainter {{priorityPainterClass}}" id="priorityPainter" [attr.priority]="priorityPainterClass">
  <span class="c-priorityPainter__dot"></span>
</div>
