import { Injectable } from '@angular/core';
import { BehaviorSubject, noop, Observable } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import { CookieService } from 'ngx-cookie';
import { TranslateService } from '@ngx-translate/core';
import { HtmlClassAndAttributeService } from '../seo/html-tags/html-class-and-lang-attribute.service';
import { DEFAULT_LANG, Lang, LANGUAGES } from './translate-helper';
import { ActivatedRouteSnapshot, Route, Router } from '@angular/router';
import { switchMap, take, tap } from 'rxjs/operators';
import { PreferredLanguageService } from './preferred-language.service';

type ShouldReuseRoute = (future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot) => boolean;

@Injectable({providedIn: 'root'})
export class LanguageService {
  currentLanguageSubject$ = new BehaviorSubject<Lang>(this.cookie.get('lang') || DEFAULT_LANG);
  currentLanguage$: Observable<Lang> = this.currentLanguageSubject$.asObservable();

  constructor(
    private cookie: CookieService,
    private translate: TranslateService,
    private router: Router,
    private preferredLanguageService: PreferredLanguageService,
    private htmlClassAndAttributeService: HtmlClassAndAttributeService,
  ) {
  }

  get currLang(): Lang {
    return this.translate.currentLang;
  }

  localeInitializer(localeId: string) {
    import(`/node_modules/@angular/common/locales/${localeId}.mjs`)
      .then((module) => registerLocaleData(module.default));
  }

  initUserLanguage() {
    this.setLanguagesLocales();
    const userLang = this.cookie.get('lang');
    const prevSessionLanguage = this.preferredLanguageService.validateLanguageCode(userLang) ? userLang : null;
    const browserLanguage = this.preferredLanguageService.getBrowserLanguage();
    const langResult = prevSessionLanguage || browserLanguage || DEFAULT_LANG;
    this.setLang(langResult).pipe(
      take(1),
      switchMap(() => this.subscribeToLangChange()),
    ).subscribe();
  }

  private setLanguagesLocales() {
    Object.keys(LANGUAGES).forEach((lang) => {
      this.localeInitializer(lang);
    });
  }

  private setRouteReuse(reuse: ShouldReuseRoute) {
    this.router.routeReuseStrategy.shouldReuseRoute = reuse;
  }

  private subscribeToLangChange(): Observable<any> {
    return this.translate.onLangChange.pipe(tap(async () => {
      const {shouldReuseRoute} = this.router.routeReuseStrategy;

      this.setRouteReuse(() => false);
      this.router.navigated = false;

      await this.router.navigateByUrl(this.router.url).catch(noop);
      this.setRouteReuse(shouldReuseRoute);
    }));
  }

  setLang(lang: Lang): Observable<Lang> {
    this.cookie.put('lang', lang);
    this.currentLanguageSubject$.next(lang);
    this.htmlClassAndAttributeService.setHtmlAttribs(lang);
    this.translate.setDefaultLang(lang);
    return this.translate.use(lang);
  }
}
