import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Activity, Priority } from '../../../../core/models/interfaces';
import { ActivityManagementService } from '../../../../core/services/activity-management.service';
import { finalize, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { GraphqlService } from '../../../../core/services/graphql.service';
import { NotificationService } from '../../../../core/services/notification.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DataproviderService } from '../../../../core/services/dataprovider.service';
import { UserLocaleService } from '../../../../shared/services/user-locale.service';
import { DateTime } from 'luxon';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker/src/app/material-timepicker/models/ngx-material-timepicker-theme.interface';
import { ngxMaterialTimepickerTheme } from '../../../../_helpers/helpers';
import { QuillModules } from 'ngx-quill/lib/quill-editor.interfaces';
import { QuillViewComponent, QuillModule } from 'ngx-quill';
import { DateTimeFormatPipe } from '../../../../shared/pipes/date-time-format.pipe';
import { DurationEntryComponent } from '../../../../core/components/objects/duration-entry/duration-entry.component';
import { AppButtonComponent } from '../../../../core/components/app-button/app-button.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatIcon } from '@angular/material/icon';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgFor, NgClass, NgIf } from '@angular/common';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';

@UntilDestroy()
@Component({
    selector: 'activity-entry-management',
    templateUrl: './activity-entry-management.component.html',
    styleUrls: ['./activity-entry-management.component.scss'],
    standalone: true,
    imports: [
        MatMenuTrigger,
        MatMenu,
        NgFor,
        NgClass,
        ReactiveFormsModule,
        FormsModule,
        NgIf,
        MatIcon,
        NgxMaterialTimepickerModule,
        AppButtonComponent,
        DurationEntryComponent,
        QuillModule,
        DateTimeFormatPipe,
        TranslateModule,
    ],
})
export class ActivityEntryManagementComponent implements AfterViewInit {
  @ViewChild('editor') editor!: QuillViewComponent;

  private _activity!: Activity;

  @Input() get activity(): Activity {
    return this._activity;
  }

  set activity(activity: Activity) {
    this._activity = activity;

    setTimeout(() => {
      this.editor.content = this._activity.note;
    }, 0);
  }
  @Input() priorities!: Priority[];

  theme: NgxMaterialTimepickerTheme = ngxMaterialTimepickerTheme;

  modules: QuillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean'],
      ['link', 'image'],
    ],
  };

  constructor(
    private activityManagementService: ActivityManagementService,
    private graphqlService: GraphqlService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private dataProviderService: DataproviderService,
    private userLocaleService: UserLocaleService,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  get timeFormat() {
    return this.userLocaleService.format.time.includes('a') ? '12' : '24';
  }

  fixedStartChanged(event: Event) {
    if (event && event.toString() !== '') {
      const format = `${this.userLocaleService.format.date} ${this.userLocaleService.format.time}`;
      this._activity.fixedStart = DateTime.fromFormat(this._activity.date.toFormat(this.userLocaleService.format.date) + ' ' + event.toString(), format);
    } else {
      this._activity.fixedStart = undefined;
    }
    this.updateActivity();
  }

  clearFixedStartChanged() {
    this._activity.fixedStart = undefined;
    this.updateActivity();
  }

  updatePriority(priority: Priority) {
    if (this._activity.priority.id === priority.id) {
      return;
    }

    this.graphqlService.updateActivityPriority(this._activity.id, priority.id)
      .pipe(take(1), untilDestroyed(this))
      .subscribe((res) => {
        this._activity.priority = res.data.update_activities_by_pk.priority;
        this.notificationService.success({
          description: this.translate.instant(marker('Priority changed')),
        });
      });
  }

  setActivityNote(content) {
    this._activity.note = content;
    this.notificationService.success({
      description: this.translate.instant(marker('Activity note is updated')),
    });
  }

  updateDuration(value: number) {
    this._activity.duration = value;
    this.updateActivity();
  }

  updateActivity() {
    this.dataProviderService.updateActivityObserver(this._activity)
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        error: (error) => {
          this.notificationService.error({
            title: this.translate.instant(marker('Something went wrong')),
            description: error,
          });
        },
      });
  }

  closeActivityInfo() {
    this.activityManagementService.setActivityData(null);
  }
}
