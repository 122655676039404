import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { DataproviderService } from '../../../../../../core/services/dataprovider.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { finalize, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserLocaleService } from '../../../../../../shared/services/user-locale.service';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker/src/app/material-timepicker/models/ngx-material-timepicker-theme.interface';
import { ngxMaterialTimepickerTheme } from '../../../../../../_helpers/helpers';
import { PreloaderService } from '../../../../../../shared/components/preloader/preloader.service';
import { TranslateModule } from '@ngx-translate/core';
import { AppButtonComponent } from '../../../../../../core/components/app-button/app-button.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgIf } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';

@UntilDestroy()
@Component({
    selector: 'add-phase-dialog',
    templateUrl: './add-phase-dialog.component.html',
    styleUrls: ['./add-phase-dialog.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatDialogClose,
        MatIcon,
        MatFormField,
        MatLabel,
        MatInput,
        NgIf,
        MatError,
        MatSuffix,
        NgxMaterialTimepickerModule,
        AppButtonComponent,
        TranslateModule,
    ],
})
export class AddPhaseDialog implements OnInit {
  form: FormGroup = this.formBuilder.group({
    name: ['', [Validators.required]],
    fixedStart: ['', Validators.required],
  });
  timepickerTheme: NgxMaterialTimepickerTheme = ngxMaterialTimepickerTheme;

  constructor(
    private formBuilder: FormBuilder,
    private userLocaleService: UserLocaleService,
    private dataProviderService: DataproviderService,
    private preloaderService: PreloaderService,
    private element: ElementRef,
    @Inject(MAT_DIALOG_DATA) public data: { date: string, templateId: number },
    public dialogRef: MatDialogRef<AddPhaseDialog>,
  ) {
  }

  ngOnInit(): void {}

  get timeFormat() {
    return this.userLocaleService.format.time.includes('a') ? '12' : '24';
  }

  getFormControlsError(name: string, error: string) {
    return this.form.get(name).hasError(error);
  }

  onSubmit() {
    this.preloaderService.showInContent(this.element.nativeElement);

    if (this.form.invalid) return;

    this.dataProviderService.createPhase(
      this.data.date,
      this.form.value.name,
      this.form.value.fixedStart,
      this.data.templateId)
      .pipe(
        take(1),
        finalize(() => this.preloaderService.close()),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  cancel() {
    this.dialogRef.close();
  }
}
