import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Phase, Priority } from '../../../../core/models/interfaces';
import { DataproviderService } from '../../../../core/services/dataprovider.service';
import { DateTime } from 'luxon';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatTabGroup } from '@angular/material/tabs';
import { CookieService } from 'ngx-cookie';
import { UserLocaleService } from 'src/app/shared/services/user-locale.service';
import {
  DayEditorOnboardingIdEnum,
  getDayEditorOnboardingTooltips,
} from '../../../../core/components/objects/onboarding-tooltips/onboarding-tooltips.helper';
import { OnboardingDialogComponent } from '../dialogs/onboarding-dialog/onboarding-dialog.component';
import { AppSettingsService } from '../../../../core/services/app-settings.service';
import { OnboardingTooltipsComponent } from '../../../../core/components/objects/onboarding-tooltips/onboarding-tooltips.component';
import { DayEditorHeaderComponent } from './components/day-editor-header/day-editor-header.component';
import { DayEditorBodyComponent } from './components/day-editor-body/day-editor-body.component';
import { PlanningBoardClipboardParent } from '../planning-board/helpers/planning-board.helper';

@UntilDestroy()
@Component({
    selector: 'app-day-editor',
    templateUrl: './day-editor.component.html',
    styleUrls: ['./day-editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        OnboardingTooltipsComponent,
        DayEditorHeaderComponent,
        DayEditorBodyComponent,
    ],
})
export class DayEditorComponent implements OnInit {
  @ViewChild('rightTabGroup') rightTabGroup: MatTabGroup;
  readonly DayEditorOnboardingIdEnum = DayEditorOnboardingIdEnum;
  readonly PlanningBoardClipboardParent = PlanningBoardClipboardParent;

  public phases: Phase[] = [];
  public priorities: Priority[] = [];
  public date: string = DateTime.now().toFormat('yyyy-MM-dd');
  public selectedDate: Date = new Date();
  public dayEditorOnboardingTooltips = null;

  constructor(
    private dataProviderService: DataproviderService,
    private dialog: MatDialog,
    private cookie: CookieService,
    public userLocaleService: UserLocaleService,
    private appSettingsService: AppSettingsService,
  ) { }

  ngOnInit(): void {
    this.dataProviderService.subscribeToDays({
      next: () => {
        this._refreshData();
      },
    });

    this.appSettingsService.subscribeToPriorities({
      next: (priorities) => {
        this.priorities = priorities;
      },
    });

    this._showFirstOnboardingDialog();
  }

  public setDate(event: { selectedDate: Date; date: string; }) {
    this.selectedDate = event.selectedDate;
    this.date = event.date;
    this.dataProviderService.changeDate(event.date);
  }

  private _showFirstOnboardingDialog() {
    const dayEditorOnboardingTooltips = getDayEditorOnboardingTooltips(this.phases.length > 0);
    if (!this.cookie.hasKey('first-onboarding-dialog')) {
      if (this.cookie.hasKey(Object.keys(dayEditorOnboardingTooltips)[0])) {
        this.cookie.remove(Object.keys(dayEditorOnboardingTooltips)[0]);
      }
      this.dialog.open(OnboardingDialogComponent, {disableClose: true}).afterClosed()
        .pipe(take(1), untilDestroyed(this))
        .subscribe(() => {
          this.dayEditorOnboardingTooltips = dayEditorOnboardingTooltips;
        });
    } else {
      this.dayEditorOnboardingTooltips = dayEditorOnboardingTooltips;
    }
  }

  private _refreshData():void {
    this.phases = this.dataProviderService.getDayPhases(this.date);
  }
}


