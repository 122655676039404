import { Component } from '@angular/core';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { SidebarMenuComponent } from '../sidebar-menu/sidebar-menu.component';

@Component({
    selector: 'app-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        RouterLinkActive,
        MatMenuTrigger,
        MatMenu,
        MatMenuItem,
        SidebarMenuComponent,
        RouterOutlet,
    ],
})
export class RootComponent {
  constructor() { }
}
