import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TIME_DURATIONS } from '../../../../_helpers/helpers';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'duration-entry',
    templateUrl: './duration-entry.component.html',
    styleUrls: ['./duration-entry.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgClass,
        MatMenuTrigger,
        MatIcon,
        MatMenu,
        NgFor,
        MatMenuItem,
        TranslateModule,
    ],
})
export class DurationEntryComponent implements OnInit {
  @Input() currentDuration: number = null;
  @Input() column: boolean = false;
  durations = TIME_DURATIONS;

  @Output() durationChanged = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }


  selectDuration(value: number) {
    this.currentDuration = value;
    this.durationChanged.emit(value);
  }
}
