import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Activity, Priority } from '../../../../../../core/models/interfaces';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList } from '@angular/cdk/drag-drop';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateModule } from '@ngx-translate/core';
import { AssignedGroupContainer, ActivityStatus, PlanningBoardClipboardParent, sortStep } from '../../helpers/planning-board.helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PlanningBoardClipboardService } from '../../services/planning-board-clipboard.service';
import { take } from 'rxjs/operators';
import { MatIcon } from '@angular/material/icon';
import { PlanningBoardActivityComponent } from '../planning-board-activity/planning-board-activity.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { ActivitiesService } from '../../services/activities.service';
import { GroupContainersService } from '../../services/group-containers.service';

const PlanningBoardClipboardStorageSizeKey = 'planning-board-size';

@UntilDestroy()
@Component({
    selector: 'planning-board-clipboard',
    templateUrl: './planning-board-clipboard.component.html',
    styleUrls: ['./planning-board-clipboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        CdkDropList,
        NgIf,
        NgFor,
        PlanningBoardActivityComponent,
        MatIcon,
        AsyncPipe,
        TranslateModule,
    ],
})
export class PlanningBoardClipboardComponent implements OnInit {
  @Input() parent: PlanningBoardClipboardParent = PlanningBoardClipboardParent.PlanningBoard;
  @Input() priorities: Priority[] = [];

  public activities: Activity[] = [];
  public groupsWithTasks: AssignedGroupContainer[] = [];
  public clipboardSize = !!localStorage.getItem(PlanningBoardClipboardStorageSizeKey)
    ? localStorage.getItem(PlanningBoardClipboardStorageSizeKey) + 'px' : '33%';
  public clipboardVisible$ = this.planningBoardClipboardService.clipboardVisible$;
  public activityStatuses: ActivityStatus[] = [];

  constructor(
    private activitiesService: ActivitiesService,
    private groupContainersService: GroupContainersService,
    private planningBoardClipboardService: PlanningBoardClipboardService,
  ) { }

  ngOnInit(): void {
    this.getClipboardTasks();
    this.fetchGroupsWithTask();

    // this.fetchStatuses();
  }

  fetchStatuses() {
    this.activitiesService.fetchStatuses$()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((statuses) => {
        this.activityStatuses = statuses;
      });
  }

  fetchGroupsWithTask() {
    this.groupContainersService.fetchGroupsWithTask()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((groups) => {
        this.groupsWithTasks = groups;
      });
  }

  toggleClipboardVisibility() {
    this.planningBoardClipboardService.toggleVisibility(!this.planningBoardClipboardService.clipboardVisible$.getValue());
  }

  cloneActivity(activity: Activity) {
    this.planningBoardClipboardService.cloneClipboardActivity(activity, marker('Clipboard activity cloned successfully'))
      .pipe(take(1), untilDestroyed(this))
      .subscribe();
  }

  getClipboardTasks() {
    this.planningBoardClipboardService.clipboardTasks$()
      .pipe(untilDestroyed(this))
      .subscribe((activities) => {
        this.activities = activities;
      });
  }

  changeClipboardSize() {
    const planningBoardWrapper: HTMLElement = document.querySelector('.c-planningBoardClipboard__wrapper');

     document.addEventListener('mousemove', setClipboardSize, false);

    function setClipboardSize(event) {
      const clipboardSize = window.innerWidth - event.clientX;
      planningBoardWrapper.style.transition = 'none';
      planningBoardWrapper.style.width = clipboardSize + 'px';
      localStorage.setItem(PlanningBoardClipboardStorageSizeKey, clipboardSize.toString());
    }

    document.addEventListener('mouseup', (event) => {
      planningBoardWrapper.style.transition = 'var(--ex-transition-primary)';
      document.removeAllListeners();
    }, false);
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer !== event.container || event.currentIndex === event.previousIndex) {
      return;
    }

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }

    const toTask = this.activities[event.currentIndex];

    toTask.order_in_clipboard = this.generateArithmeticMeanSorting(event.currentIndex);

    this.activitiesService.updateActivityByPk$(toTask)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(() => {

      }, () => {
        if (event.previousContainer === event.container) {
          moveItemInArray(event.container.data, event.currentIndex, event.previousIndex);
        } else {
          transferArrayItem(
            event.container.data,
            event.previousContainer.data,
            event.currentIndex,
            event.previousIndex,
          );
        }
      });
  }

  private generateArithmeticMeanSorting(currentIndex) {
    const previousTaskBeforeToTask = this.activities[currentIndex - 1];
    const nextTaskAfterToTask = this.activities[currentIndex + 1];

    let arithmeticMeanSorting = sortStep;

    if (currentIndex !== 0 && currentIndex !== this.activities.length - 1) {
      arithmeticMeanSorting = (nextTaskAfterToTask.order_in_clipboard + previousTaskBeforeToTask.order_in_clipboard) / 2;
    } else {
      if (this.activities.length > 1) {
        if (currentIndex === 0) {
          arithmeticMeanSorting = nextTaskAfterToTask.order_in_clipboard / 2;
        }

        if (currentIndex === this.activities.length - 1) {
          arithmeticMeanSorting = previousTaskBeforeToTask.order_in_clipboard + sortStep;
        }
      }
    }
    return arithmeticMeanSorting;
  }
}
