import { DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, filter, switchMap, take } from 'rxjs';
import { AppButtonComponent } from 'src/app/core/components/app-button/app-button.component';
import { Activity, Phase, Priority, Template } from 'src/app/core/models/interfaces';
import { UserLocaleService } from 'src/app/shared/services/user-locale.service';
import { DayEditorOnboardingIdEnum } from 'src/app/core/components/objects/onboarding-tooltips/onboarding-tooltips.helper';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { DataproviderService } from 'src/app/core/services/dataprovider.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationService } from 'src/app/core/services/notification.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { PhaseListComponent } from '../../../phase-list/phase-list.component';
import { ActivityEntryManagementComponent } from '../../../activity-entry-management/activity-entry-management.component';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { AppointmentListComponent } from '../../../appointment-list/appointment-list.component';
import { ActivityManagementService } from 'src/app/core/services/activity-management.service';
import { AddPhaseDialog } from '../add-phase-dialog/add-phase-dialog.component';
import { SelectTemplateDialog } from '../select-template-dialog/select-template-dialog.component';
import { ActivitiesService } from '../../../planning-board/services/activities.service';
import { ActivityStatus } from '../../../planning-board/helpers/planning-board.helper';

@UntilDestroy()
@Component({
    selector: 'day-editor-body',
    templateUrl: './day-editor-body.component.html',
    styleUrls: ['./day-editor-body.component.scss'],
    standalone: true,
    imports: [
      NgIf,
      NgFor,
      NgClass,
      TranslateModule,
      DatePipe,
      AppButtonComponent,
      PhaseListComponent,
      ActivityEntryManagementComponent,
      MatIcon,
      MatMenu,
      MatTabGroup,
      MatTab,
      MatMenuTrigger,
      AppointmentListComponent,
    ],
})
export class DayEditorBodyComponent implements OnInit, AfterViewInit {
  public readonly DayEditorOnboardingIdEnum = DayEditorOnboardingIdEnum;

  @Input() selectedDate: Date = new Date();
  @Input() phases: Phase[] = [];
  @Input() date: string;
  @Input() priorities: Priority[] = [];

  public priorityPainterEnable = false;
  public priorityPainterIndex = 0;
  public activityStatuses: ActivityStatus[] = [];
  public activityMoreInfoData: Activity = null;

  private _templates: Template[] = [];

  public changePriorities$ = new BehaviorSubject(false);

  constructor(
    public userLocaleService: UserLocaleService,
    private dialog: MatDialog,
    private dataProviderService: DataproviderService,
    private _notificationService: NotificationService,
    private translate: TranslateService,
    private activitiesService: ActivitiesService,
    private activityManagementService: ActivityManagementService,
    private cd: ChangeDetectorRef,
  ) {
    this._fetchActivitiesStatuses();
  }

  ngOnInit(): void {
    this.dataProviderService.subscribeToTemplates({
      next: (templates) => this._templates = templates,
    });
  }

  ngAfterViewInit(): void {
    this._getMoreInfoForActivity();
  }

  get priorityIdByPainterIndex(): number {
    return this.priorities[this.priorityPainterIndex].id;
  }

  public setPriorityPainterIndex(priority: Priority): void {
    this.priorityPainterIndex = this.priorities.findIndex((el) => el.id === priority.id);
  }

  public openPhaseAddDialog(): void {
    this.dialog.open(AddPhaseDialog, {
      data: {
        date: this.date,
        templateId: -1,
      },
    });
  }

  public setPriorityPainterData($event: { enable: boolean; priorityPainterIndex: number }): void {
    this.priorityPainterEnable = $event.enable;
    this.priorityPainterIndex = $event.priorityPainterIndex;
  }

  public addFromTemplate(): void {
    const dialogRef = this.dialog.open(SelectTemplateDialog, {
      data: {templates: this._templates.filter((tpl) => !!tpl.active)},
    });
    dialogRef.afterClosed().pipe(
      take(1),
      filter((template) => !!template),
      switchMap((template: Template) => this.dataProviderService.insertNewDayFromTemplate(template.id, this.date)),
      untilDestroyed(this),
    ).subscribe();
  }

  public deleteDay(): void {
    this.dataProviderService.deleteDay(this.date)
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: () => {
          this._notificationService.success({
            title: this.translate.instant(marker('Very well...')),
            description: this.translate.instant(marker('Successfully deleted day')),
          });
        },
        error: (error) => {
          this._notificationService.error({
            title: this.translate.instant(marker('Well, failure...')),
            description: this.translate.instant(marker('Failed to delete day')),
          });
        },
      });
  }

  private _fetchActivitiesStatuses(): void {
    this.activitiesService.fetchStatuses$()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((statuses) => {
        this.activityStatuses = statuses;
      });
  }

  private _getMoreInfoForActivity(): void {
    this.activityManagementService.activityData$
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.activityMoreInfoData = data;
        this.cd.detectChanges();
      });
  }

}
