import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { NotificationService } from '../../../services/notification.service';
import { LanguageService } from '../../../../shared/translates/language.service';
import { LANGUAGES } from 'src/app/shared/translates/translate-helper';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NgFor, AsyncPipe, KeyValuePipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';

@UntilDestroy()
@Component({
    selector: 'language-selection',
    templateUrl: './language-selection.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        MatMenuTrigger,
        MatIcon,
        MatMenu,
        NgFor,
        MatMenuItem,
        AsyncPipe,
        KeyValuePipe,
    ],
})
export class LanguageSelectionComponent implements OnInit {
  currentLang$ = this.languageService.currentLanguageSubject$;
  LANGUAGES = LANGUAGES;
  isLoggedIn = false;
  originalSort = () => 0;

  constructor(
    private auth: AuthService,
    // private userService: UserService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private notificationService: NotificationService,
  ) {
  }

  ngOnInit(): void {
    this.auth.isLoggedIn$()
      .pipe(untilDestroyed(this))
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      });
  }

  setLanguage(lang: string) {
    this.languageService.setLang(lang)
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        error: (error) => this.notificationService.error({title: this.translate.instant(marker('Ops... Language error')), description: error}),
      });
  }
}
