import { createAction, props } from '@ngrx/store';
import { Template } from '../../core/models/interfaces';
import { Update } from '@ngrx/entity';
import { ToastTypes } from '../../core/services/notification.service';

// load
export const loadePhaseTemplatesTreeAction = createAction('[PhaseTemplates] Load Phase Templates Tree');
export const loadPhaseTemplatesTreeSuccessAction = createAction('[PhaseTemplates] Load Phase Templates Success', props<{ responce: Template[] }>());

// add
export const createTemplateAction = createAction('[PhaseTemplates] Create Template', props<{ name: string; active: boolean; msg: string; }>());
export const cloneTemplateAction = createAction('[PhaseTemplates] Clone Template', props<{ selectedTemplateId: number; name: string; msg: string; }>());
export const addTemplateAction = createAction('[PhaseTemplates] Add Template Action', props<{ template: Template }>());

// update
export const updateTemplateAction = createAction('[PhaseTemplates] Update Template', props<{ templateId: number; name: string; active: boolean; msg: string; }>());
export const updateTemplateInStoreAction = createAction('[PhaseTemplates] Update Template In Store', props<{ update: Update<Template> }>());
export const updateSelectedTemplateIdAction = createAction('[PhaseTemplates] Update Selected Template Id In Store', props<{ id: number; }>());

// delete
export const deleteTemplateAction = createAction('[PhaseTemplates] Delete Template', props<{ selectedTemplateId: number; msg: string; }>());
export const deleteTemplateInStoreAction = createAction('[PhaseTemplates] Delete Template In Store', props<{ id: number; }>());

// extra actions
export const showToastPhaseTemplatesAction = createAction('[PhaseTemplates] Show Toast Phase Templates', props<{ toastType: ToastTypes; errorMsg: string; }>());
