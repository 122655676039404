import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ThemeService } from './core/services/theme.service';
import { CookieService } from 'ngx-cookie';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from './modules/auth/services/auth.service';
import { RouterLinkWithHref, RouterOutlet } from '@angular/router';
import { NgProgressModule } from 'ngx-progressbar';
import { PlanningBoardClipboardComponent } from './modules/day-planner/components/planning-board/components/planning-board-clipboard/planning-board-clipboard.component';
import { AppSettingsService } from './core/services/app-settings.service';
import { PlanningBoardClipboardService } from './modules/day-planner/components/planning-board/services/planning-board-clipboard.service';
import { take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Priority } from './core/models/interfaces';
import { environment } from '../environments/environment';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgProgressModule, RouterOutlet, RouterLinkWithHref, PlanningBoardClipboardComponent],
})
export class AppComponent implements OnInit {
  public title = 'BeMyDay';

  public priorities: Priority[] = [];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private cookie: CookieService,
    private auth: AuthService,
    private themeService: ThemeService,
    private appSettingsService: AppSettingsService,
    private planningBoardClipboardService: PlanningBoardClipboardService,
  ) {
    this._getAllClipboardActivities();
    console.log(`Portal URL: ${environment.frontendHost}`)
  }

  ngOnInit(): void {
    this._subscribeToPriorities();

    this.matIconRegistry.addSvgIconSet(this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/icons/sprites/global.svg`));
    this.themeService.initTheme(this.cookie.get('dark'));
  }

  private _getAllClipboardActivities(): void {
    this.planningBoardClipboardService.getAllClipboardActivities()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((res) => {
        this.planningBoardClipboardService.setClipboardActivities(res.data.activities);
      });
  }

  private _subscribeToPriorities(): void {
    this.appSettingsService.subscribeToPriorities({
      next: (priorities) => {
        this.priorities = priorities;
      },
    });
  }
}
