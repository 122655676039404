import { inject, Injectable } from '@angular/core';
import { catchError, exhaustMap, finalize, map, of, switchMap, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationService } from '../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { GraphqlService } from '../../core/services/graphql.service';
import { ShowToastEffect } from '../common/show-toast-effect/show-toast-effect';
import {
    addActivityInStoreAction,
    cloneActivityAction,
    deleteActivityAction,
    deleteActivityInStoreAction,
    showToastActivitiesAction,
    sortDayFromActivitiesStoreAction,
    updateActivityAction,
    updateActivityInStoreAction,
    updateActivityPriorityAction,
    updateTaskActivityAction,
} from './activities.actions';
import { NgProgressService } from '../../shared/services/ng-progress.service';
import { PhasesService } from '../../core/services/phases.service';
import { DataproviderService } from '../../core/services/dataprovider.service';
import { DateTime } from 'luxon';
import { ActivitiesService } from '../../modules/day-planner/components/planning-board/services/activities.service';

@Injectable()
export class ActivitiesEffect extends ShowToastEffect {

    constructor(
        private _actions$: Actions,
        private _graphqlService: GraphqlService,
        private _progressService: NgProgressService,
        private _phasesService: PhasesService,
        private _dataproviderService: DataproviderService,
        private _activitiesService: ActivitiesService,
        private notificationService: NotificationService,
        private translate: TranslateService,
    ) {
        super(notificationService, translate);
    }

    deleteActivityFromList$ = createEffect(() => this._actions$.pipe(
        ofType(deleteActivityAction),
        tap(() => this._progressService.start()),
        switchMap((action) =>
            (
                !action.activity.task || !!action.activity.task?.is_implicit
                    ? this._graphqlService.deleteActivityFromList(action.activity.id)
                    : this._graphqlService.removeActivityFromList(action.activity.id)
            )
            .pipe(
                finalize(() => this._progressService.complete()),
                exhaustMap((result) => {
                    if (action.activity.appointment) {
                        action.activity.appointment.planned = false;
                        this._dataproviderService.lastUpdatedAppointment$.next(action.activity.appointment);
                    }

                    this._phasesService.updateChangedActivities(result.changedActivities);
                    return [
                        deleteActivityInStoreAction({ id: action.activity.id }),
                        sortDayFromActivitiesStoreAction({
                            date: typeof action.activity.date === 'string' ? action.activity.date : action.activity.date?.toFormat('yyyy-MM-dd'),
                            templateId: action.activity.templateId,
                            msg: action.msg,
                        }),
                    ];
                }),
                catchError((error: { message: string }) =>
                    of(showToastActivitiesAction({ toastType: 'error',  errorMsg: error.message })),
                ),
            )),
        ),
    );

    updateActivity$ = createEffect(() => this._actions$.pipe(
        ofType(updateActivityAction),
        tap(() => this._progressService.start()),
        exhaustMap((action) =>
             this._graphqlService.updateActivity(action.activity)
            .pipe(
                finalize(() => this._progressService.complete()),
                map(() => updateActivityInStoreAction({
                    update: {
                        id: action.activity.id,
                        changes: action.activity,
                    },
                })),
                tap(() => this.showNotification('success', action.msg)),
                catchError((error: { message: string }) =>
                    of(showToastActivitiesAction({ toastType: 'error',  errorMsg: error.message })),
                ),
            )),
        ),
    );

    updateActivityPriority$ = createEffect(() => this._actions$.pipe(
        ofType(updateActivityPriorityAction),
        tap(() => this._progressService.start()),
        exhaustMap((action) =>
            this._activitiesService.updateActivityPriority(action.changes.activityId, action.changes.priorityId)
            .pipe(
                finalize(() => this._progressService.complete()),
                exhaustMap((result) => {
                    return [
                        updateActivityInStoreAction({ update: {
                            id: action.changes.activityId,
                            changes: { priority: result.data.update_activities_by_pk.priority },
                        } }),
                        sortDayFromActivitiesStoreAction({
                            date: typeof action.changes.date === 'string' ? action.changes.date : action.changes.date?.toFormat('yyyy-MM-dd'),
                            templateId: action.changes.templateId,
                            msg: action.msg,
                        }),
                    ];
                }),
                catchError((error: { message: string }) =>
                    of(showToastActivitiesAction({ toastType: 'error',  errorMsg: error.message })),
                ),
            )),
        ),
    );

    assignTaskToActivity$ = createEffect(() => this._actions$.pipe(
        ofType(updateTaskActivityAction),
        tap(() => this._progressService.start()),
        exhaustMap((action) =>
            this._activitiesService.assignTaskToActivity(action.activity.id, action.activity.task?.id ?? null)
            .pipe(
                finalize(() => this._progressService.complete()),
                exhaustMap((result) => {
                    this._activitiesService.newActivityAssignedTask$.next({ oldActivity: action.oldActivity, newActivity: action.activity});

                    return [
                        updateActivityInStoreAction({ update: {
                            id: action.activity.id,
                            changes: action.activity,
                        } }),
                        sortDayFromActivitiesStoreAction({
                            date: typeof action.activity.date === 'string' ? action.activity.date : action.activity.date?.toFormat('yyyy-MM-dd'),
                            templateId: action.activity.templateId,
                            msg: action.msg,
                        }),
                    ];
                }),

                catchError((error: { message: string }) =>
                    of(showToastActivitiesAction({ toastType: 'error',  errorMsg: error.message })),
                ),
            )),
        ),
    );

    cloneActivity$ = createEffect(() => this._actions$.pipe(
        ofType(cloneActivityAction),
        tap(() => this._progressService.start()),
        switchMap((action) =>
            this._graphqlService.cloneActivity(action.activity.id)
            .pipe(
                finalize(() => this._progressService.complete()),
                exhaustMap((result) => {
                    const act = result.data.cloneActivity.activity;
                    if (act.fixedStart) {
                        act.fixedStart = DateTime.fromSQL(act.date + ' ' + act.fixedStart);
                    }
                    act.date = DateTime.fromSQL(act.date);
                    act.id = +act.id;
                    if (act.templateId !== undefined) {
                        act.templateId = +act.templateId;
                    }
                    if (!!action.activity.task) {
                        act.task = action.activity.task;
                    }
                    this._phasesService.updateChangedActivities(result.data.cloneActivity.changedActivities);

                    return [
                        addActivityInStoreAction({ activity: act }),
                        sortDayFromActivitiesStoreAction({
                            date: typeof action.activity.date === 'string' ? action.activity.date : action.activity.date?.toFormat('yyyy-MM-dd'),
                            templateId: action.activity.templateId,
                            msg: action.msg,
                        }),
                    ];
                }),
                catchError((error: { message: string }) =>
                    of(showToastActivitiesAction({ toastType: 'error',  errorMsg: error.message })),
                ),
            )),
        ),
    );

    sortDayAction$ = createEffect(
        () => {
            return inject(Actions)
            .pipe(
                ofType(sortDayFromActivitiesStoreAction),
                tap((action) => {
                    this._phasesService.sortDay$(action.date, action.templateId)
                        .subscribe(() => this._phasesService.refreshDays());
                }),
                tap((action) => this.showNotification('success', action.msg)),
            );
    },
    { functional: true, dispatch: false });

    showToastAction$ = createEffect(
        () => {
            return inject(Actions)
            .pipe(
                ofType(showToastActivitiesAction),
                tap(({ toastType,  errorMsg }) => this.showNotification(toastType, errorMsg)),
            );
    },
    { functional: true, dispatch: false });
}
