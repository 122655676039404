<div class="c-activityList"
     [cdkDropListData]="activities"
     (cdkDropListDropped)="drop($event)" cdkDropList>
  <ng-container *ngIf="activities.length else activitiesEmpty">
    <div class="c-activityList__item" *ngFor="let activity of activities" (cdkDragStarted)="cdkDragMove.next(false)" cdkDrag>
      <div class="c-activityList__placeholder" *cdkDragPlaceholder></div>
      <app-activity-list-entry *ngIf="!activity.phaseDefault"
                               [groupsWithTasks]="groupsWithTasks"
                               [activityStatuses]="activityStatuses"
                               [lastContacts]="peopleContacts"
                               [googleContactsEnabled]="googleContactsEnabled"
                               [phase]="phase"
                               [priorityPainterClass]="priorityPainterClass"
                               [priorities]="priorities"
                               [activity]="activity"
                               [activitiesList]="activities"
                               [dayEditor]="dayEditor">
      </app-activity-list-entry>
    </div>
  </ng-container>

  <ng-template #activitiesEmpty>
    <div data-testid="add_activity_button" class="c-activityList__itemCreate" (click)="addActivity()">
      <mat-icon svgIcon="add"></mat-icon>
      <p>{{'Create activity' | translate}}</p>
    </div>
  </ng-template>
</div>
