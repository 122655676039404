import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { Activity, Phase, Priority } from '../../../../core/models/interfaces';
import { DataproviderService } from '../../../../core/services/dataprovider.service';
import { DateTime } from 'luxon';
import {
  NgxMaterialTimepickerTheme,
} from 'ngx-material-timepicker/src/app/material-timepicker/models/ngx-material-timepicker-theme.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { MatMenu, MatMenuTrigger, MatMenuItem } from '@angular/material/menu';

import { debounceTime, finalize, take } from 'rxjs/operators';
import { NotificationService } from '../../../../core/services/notification.service';
import { ngxMaterialTimepickerTheme } from '../../../../_helpers/helpers';
import { UserLocaleService } from '../../../../shared/services/user-locale.service';
import { NgProgressService } from '../../../../shared/services/ng-progress.service';
import { GraphqlService } from '../../../../core/services/graphql.service';
import {
  GoogleContactsInterface,
  GoogleContactsService,
  maxContactsNumber,
} from '../../../google/services/google-contacts.service';
import { AppSettingsService } from '../../../../core/services/app-settings.service';
import { ActivityManagementService } from '../../../../core/services/activity-management.service';
import { PlanningBoardClipboardService } from '../planning-board/services/planning-board-clipboard.service';
import { DateTimeFormatPipe } from '../../../../shared/pipes/date-time-format.pipe';
import { AppButtonComponent } from '../../../../core/components/app-button/app-button.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DurationEntryComponent } from '../../../../core/components/objects/duration-entry/duration-entry.component';
import { ActivityEntryInfoOverlayComponent } from './activity-entry-info-overlay/activity-entry-info-overlay.component';
import { ActivityAssignedTaskComponent } from '../../../../core/components/objects/activity-assigned-task/activity-assigned-task.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { NgClass, NgFor, NgIf, AsyncPipe } from '@angular/common';
import { ActivityStatus, AssignedGroupContainer } from '../planning-board/helpers/planning-board.helper';

const CallKeyWords = [marker('call '), marker('call to '), marker('phone '), marker('phone number ')];
const MailKeyWords = [marker('send mail to '), marker('mail '), marker('send '), marker('mail to '), marker('write '), marker('to write a letter ')];

@UntilDestroy()
@Component({
    selector: 'app-activity-list-entry',
    templateUrl: './activity-list-entry.component.html',
    standalone: true,
    imports: [
        NgClass,
        MatIcon,
        MatMenuTrigger,
        MatMenu,
        NgFor,
        ReactiveFormsModule,
        FormsModule,
        NgIf,
        ActivityAssignedTaskComponent,
        ActivityEntryInfoOverlayComponent,
        DurationEntryComponent,
        NgxMaterialTimepickerModule,
        AppButtonComponent,
        MatMenuItem,
        AsyncPipe,
        DateTimeFormatPipe,
        TranslateModule,
    ],
})
export class ActivityListEntryComponent implements AfterViewInit {
  @ViewChild('contactsMenuTrigger', {static: true}) contactsMenuTrigger: MatMenuTrigger;
  @ViewChild('contactsMenu', {static: true}) contactsMenu: MatMenu;
  @Input() activity!: Activity;

  theme: NgxMaterialTimepickerTheme = ngxMaterialTimepickerTheme;
  activityData$ = this.activityManagementService.activityData$;

  @Input() get activitiesList(): Activity[] {
    return this.activities;
  }

  set activitiesList(activities: Activity[]) {
    this.activities = activities;
    if (!!this.newActivity) {
      const actIndex = this.activities.findIndex((el) => el.id === this.activity.id);
      moveItemInArray(this.activities, this.activities.length - 1, actIndex);
      this.dataProviderService.moveActivityById(this.newActivity, this.activity, 'BEFORE')
        .pipe(take(1), untilDestroyed(this))
        .subscribe(() => this.newActivity = null);
    }
  }


  @Input() readonly lastContacts: GoogleContactsInterface[] = [];
  @Input() googleContactsEnabled: boolean = false; // indicates whether the component is used in DayView or in TemplateView used to switch the moveToBacklog button on/off
  @Input() phase!: Phase;
  @Input() priorities: Priority[] = [];
  @Input() dayEditor: boolean = true; // indicates whether the component is used in DayView or in TemplateView used to switch the moveToBacklog button on/off
  @Input() priorityPainterClass = '';
  @Input() groupsWithTasks: AssignedGroupContainer[];
  @Input() activityStatuses: ActivityStatus[];

  allContacts: GoogleContactsInterface[] = [];
  activities!: Activity[];
  loading = true;
  error: string = '';
  newActivity: Activity;
  isMailKeyWord = '';
  isCallKeyWord = '';
  searchPeopleValue = '';

  constructor(
    private dataProviderService: DataproviderService,
    private graphqlService: GraphqlService,
    private notificationService: NotificationService,
    private progressService: NgProgressService,
    private userLocaleService: UserLocaleService,
    private googleContactsService: GoogleContactsService,
    private appSettingsService: AppSettingsService,
    private translate: TranslateService,
    private activityManagementService: ActivityManagementService,
    private planningBoardClipboardService: PlanningBoardClipboardService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngAfterViewInit() {
    this.setContacts(this.lastContacts);
  }

  get priorityPainterId() {
    return this.priorities.find((el) => el.description === this.priorityPainterClass).id;
  }

  get savedContacts() {
    return this.googleContactsService.savedContacts;
  }

  get contacts(): GoogleContactsInterface[] {
    if (this.isMailKeyWord) {
      return this.allContacts.filter((el) => el.emails.length);
    }
    if (this.isCallKeyWord) {
      return this.allContacts.filter((el) => el.phones.length);
    }

    return [];
  }

  get isActiveNowActivity() {
    return this.activity.actStart < DateTime.now() && DateTime.now() < this.activity.actEnd;
  }

  setContacts(contacts: GoogleContactsInterface[]) {
    this.allContacts = this.savedContacts.length >= maxContactsNumber ? this.savedContacts : contacts;
  }

  updatePriority(activityId: number, priorityId: number) {
    if (this.activity.priority.id === priorityId) {
      return;
    }

    this.graphqlService.updateActivityPriority(activityId, priorityId)
      .pipe(take(1), untilDestroyed(this))
      .subscribe((res) => {
        this.activity.priority = res.data.update_activities_by_pk.priority;
        this.notificationService.success({
          description: this.translate.instant(marker('Priority changed')),
        });
      });
  }

  durationChanged(value: number) {
    this.activity.duration = value;
    this.onSave();
  }

  doneChanged() {
    this.onSave();
  }

  descriptionChanged() {
    this.onSave();
  }

  descriptionEntering(value: string) {
    if (!this.googleContactsEnabled || !value.length || value.length < 2) return;

    this.isCallKeyWord = CallKeyWords.find((word) => value.includes(this.translate.instant(word)));
    this.isMailKeyWord = MailKeyWords.find((word) => value.includes(this.translate.instant(word)));
    if (this.isCallKeyWord || this.isMailKeyWord && this.contactsMenu.closed) {
      const keyWord = this.translate.instant(this.isCallKeyWord || this.isMailKeyWord);
      const fromValue = value.search(keyWord);
      const toValue = value.length;
      this.searchPeopleValue = value.substring(fromValue, toValue).replace(keyWord, '');
      this.contactsMenuTrigger.openMenu();

      if (!this.searchPeopleValue?.length) {
        return this.setContacts(this.lastContacts);
      }

      this.googleContactsService.searchPeople(this.searchPeopleValue)
        .pipe(take(1), debounceTime(250), untilDestroyed(this))
        .subscribe((people) => {
          this.allContacts = people;
        });
    } else {
      this.contactsMenuTrigger.closeMenu();
    }
  }

  setActivityCallDescription(activity: Activity, contact: GoogleContactsInterface, email: string) {
    activity.description = `${activity.description} ${contact.displayName} (${email})`.replace(this.searchPeopleValue, '');
    this.descriptionChanged();
    this.contactsMenuTrigger.closeMenu();
    this.googleContactsService.saveNewContact(contact);
  }

  get timeFormat() {
    return this.userLocaleService.format.time.includes('a') ? '12' : '24';
  }

  fixedStartChanged(event: Event) {
    if (event && event.toString() !== '') {
      const format = `${this.userLocaleService.format.date} ${this.userLocaleService.format.time}`;
      this.activity.fixedStart = DateTime.fromFormat(this.activity.date.toFormat(this.userLocaleService.format.date) + ' ' + event.toString(), format);
    } else {
      this.activity.fixedStart = undefined;
    }
    this.onSave();
  }

  clearFixedStartChanged() {
    this.activity.fixedStart = undefined;
    this.onSave();
  }

  deleteActivity() {
    this.dataProviderService.deleteActivity(this.activity)
      .pipe(take(1), untilDestroyed(this))
       .subscribe({
        next: () => {},
        error: (error) => {
          this.notificationService.error({
            title: this.translate.instant(marker('Failure to delete...')),
            description: error,
          });
        },
       });
  }

  cloneActivity() {
    this.progressService.start();
    this.dataProviderService.cloneActivity(this.activity)
      .pipe(
        take(1),
        finalize(() => this.progressService.complete()),
        untilDestroyed(this),
      ).subscribe({
        next: () => {},
        error: (error) => {
          this.notificationService.error({
            title: this.translate.instant(marker('Failure to cloning...')),
            description: error,
          });
        },
       });
  }

  onSave() {
    this.dataProviderService.updateActivityObserver(this.activity)
      .pipe(
        take(1),
        finalize(() => this.progressService.complete()),
        untilDestroyed(this),
      ).subscribe({
        next: () => {},
        error: (error) => {
          this.notificationService.error({
            title: this.translate.instant(marker('Something went wrong')),
            description: error,
          });
        },
       });
  }

  // TODO: what does toSomeOrder means?
  addActivity(toSomeOrder: boolean) {
    this.progressService.start();
    const activityDuration = 15; // FIXME: bad to use - need global values or settings
    this.dataProviderService.addNewActivityToPhase(this.phase, '', activityDuration, null)
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: (response) => {
          if (toSomeOrder) {
            this.newActivity = response.data.newActivityToPhase.activity;
          }
          this.progressService.complete();
          this.notificationService.success({
            title: this.translate.instant(marker('Plus one a day')),
            description: this.translate.instant(marker('Activity added successfully')),
          });
        },
        error: (error) => {
          this.notificationService.error({
             title: this.translate.instant(marker('Failure to adding...')),
            description: error,
          });
        },
       });
  }

  moveToClipboard() {
    if (this.activity.in_clipboard) {
      return;
    }
    // FIXME:
    // put this two Observables together!
    this.planningBoardClipboardService.addClipboardActivity(this.activity)
      .pipe(take(1), untilDestroyed(this)).subscribe(() => {
      if (!this.planningBoardClipboardService.clipboardVisible$.getValue()) {
        this.planningBoardClipboardService.toggleVisibility(true);
      }
    });

    this.dataProviderService.pullActivityFromPhase(this.activity)
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: () => {},
        error: (error) => {
          this.notificationService.error({
            title: this.translate.instant(marker('Failure to move to Clipboard')),
            description: error,
          });
        },
       });

    // TODO: this is for new activity list handling:
    // ---------------------------------
    // order_in_phase set to null
    // activity.update!(in_clipboard: true, previous_activity: nil, next_activity: nil, done: nil, phase_id: nil, date: nil)
    // new graphql query - moveActivityToClipboard
  }
}
