import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { THEME_KEYS, ThemeService } from './core/services/theme.service';
import { CookieService } from 'ngx-cookie';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationStart, Router, RouterLinkWithHref, RouterOutlet } from '@angular/router';
import { ClipboardComponent } from './modules/day-planner/components/clipboard/clipboard.component';
import { Priority } from './core/models/interfaces';
import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgIf, registerLocaleData } from '@angular/common';
import { AvailableClipboardRoutes } from './core/constants/available-clipboard-routes';
import { NgProgressModule } from 'ngx-progressbar';
import localeUk from '@angular/common/locales/uk';
import localeDe from '@angular/common/locales/de';
import localeRu from '@angular/common/locales/ru';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
      NgIf,
      NgProgressModule,
      RouterOutlet,
      RouterLinkWithHref,
      ClipboardComponent,
    ],
})
export class AppComponent implements OnInit {
  public title = 'BeMyDay';

  public priorities: Priority[] = [];
  public showClipboard: boolean = false;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private cookie: CookieService,
    private themeService: ThemeService,
    private router: Router,
  ) {
     registerLocaleData(localeUk);
     registerLocaleData(localeDe);
     registerLocaleData(localeRu);

     this.router.events
      .pipe(
          filter((event) => event instanceof NavigationStart),
          untilDestroyed(this),
      )
      .subscribe((event: NavigationStart) => {
        this.showClipboard = !event?.url.includes(AvailableClipboardRoutes.PLANNING_BOARD);
      });
  }

  ngOnInit(): void {
    this.matIconRegistry.addSvgIconSet(this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/icons/sprites/global.svg`));
    this.themeService.initTheme(this.themeService.isDark ? THEME_KEYS.Dark : THEME_KEYS.Light);
  }
}
