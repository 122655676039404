import { AfterViewInit, Component, Input, TemplateRef } from '@angular/core';
import { Activity } from '../../../models/interfaces';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { lightOrDark } from '../../../../_helpers/helpers';
import { NotificationService } from '../../../services/notification.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgTemplateOutlet, NgFor, NgClass } from '@angular/common';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { ActivitiesService } from '../../../../modules/day-planner/components/planning-board/services/activities.service';
import { AssignedGroupContainer, AssignedGroupContainerTask } from '../../../../modules/day-planner/components/planning-board/helpers/planning-board.helper';

@UntilDestroy()
@Component({
    selector: 'activity-assigned-task',
    templateUrl: './activity-assigned-task.component.html',
    styleUrls: ['./activity-assigned-task.component.scss'],
    standalone: true,
    imports: [
        MatMenuTrigger,
        NgIf,
        NgTemplateOutlet,
        MatMenu,
        NgFor,
        MatIcon,
        NgClass,
        TranslateModule,
    ],
})
export class ActivityAssignedTaskComponent implements AfterViewInit {
  @Input() activity: Activity;
  @Input() trigger: TemplateRef<any>;
  @Input() groupsWithTasks: AssignedGroupContainer[];

  constructor(
    private activitiesService: ActivitiesService,
    private notificationService: NotificationService,
    private translate: TranslateService,
  ) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.groupHaveAssignedTask();
    }, 15);
  }

  get activityGroup(): AssignedGroupContainer {
    if (!this.activity.task || !this.activity.task.group) {
      return null;
    }

    return this.groupsWithTasks.find((group) => group.id === this.activity.task.group.id);
  }

  get activityGroupTask(): AssignedGroupContainerTask {
    if (!this.activity.task || !this.activityGroup) {
      return null;
    }

    return this.activityGroup.tasks.find((task) => task.id === this.activity.task.id);
  }

  lightOrDark(color: string) {
    return lightOrDark(color);
  }

  removeTaskBinding() {
    const oldActivity = this.prepareCopyActivity(this.activity);
    this.activity.task = null;
    this.activitiesService.newActivityAssignedTask$.next({oldActivity, newActivity: this.activity});
    this.assignTask(this.activity, null, marker('Task binding successfully removed'));
  }

  assignTaskToActivity(task: AssignedGroupContainerTask, group: AssignedGroupContainer) {
    if (this.activity.task?.id === task.id) {
      return;
    }
    const oldActivity = this.prepareCopyActivity(this.activity);

    this.activity.task = {
      id: task.id,
      is_implicit: task.is_implicit,
      description: task.description,
      group: {
        id: group.id,
        title: group.title,
      },
    };
    this.activitiesService.newActivityAssignedTask$.next({oldActivity, newActivity: this.activity});
    this.assignTask(this.activity, task.id, marker('Task successfully assigned'));
  }

  assignTask(activity: Activity, taskId: string, msg = '') {
    this.activitiesService.assignTaskToActivity(activity.id, taskId)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(() => {
        if (!!msg) {
          this.notificationService.success({
            description: this.translate.instant(msg),
          });
        }
      });
  }

  groupHaveAssignedTask() {
    this.groupsWithTasks.forEach((group) => {
      group.expanded = this.activityGroup?.id === group.id;
    });
  }

  private prepareCopyActivity(activity: Activity): Activity {
    return {
      id: activity.id,
      task: activity.task,
      in_clipboard: activity.in_clipboard,
      date: activity.date,
      description: activity.description,
      duration: activity.duration,
      note: activity.note,
      order_in_clipboard: activity.order_in_clipboard,
      order_in_phase: activity.order_in_phase,
      order_in_task: activity.order_in_task,
      templateId: activity.templateId,
      priority: activity.priority,
      status: activity.status,
      done: activity.done,
      appointment: activity.appointment,
    };
  }
}
