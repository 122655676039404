import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList, CdkDrag, CdkDragPlaceholder } from '@angular/cdk/drag-drop';
import { Activity, Phase, Priority, Template } from '../../../../core/models/interfaces';
import { DataproviderService } from '../../../../core/services/dataprovider.service';
import { NotificationService } from '../../../../core/services/notification.service';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cdkDragMove, DropDirectionState, getDirectionState } from '../../../../_helpers/helpers';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { GoogleContactsInterface } from '../../../google/services/google-contacts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityManagementService } from '../../../../core/services/activity-management.service';
import { PhaseSorter } from '../../../../_helpers/phase-sorter';
import { MatIcon } from '@angular/material/icon';
import { ActivityListEntryComponent } from '../activity-list-entry/activity-list-entry.component';
import { NgIf, NgFor } from '@angular/common';
import { ActivitiesService } from '../planning-board/services/activities.service';
import { ActivityStatus, AssignedGroupContainer } from '../planning-board/helpers/planning-board.helper';

// the component holds all sorted activities per date
@UntilDestroy()


@Component({
    selector: 'app-activity-list',
    templateUrl: './activity-list.component.html',
    standalone: true,
    imports: [
        CdkDropList,
        NgIf,
        NgFor,
        CdkDrag,
        CdkDragPlaceholder,
        ActivityListEntryComponent,
        MatIcon,
        TranslateModule,
    ],
})
export class ActivityListComponent implements OnInit, AfterViewInit {
  @Input() phase!: Phase;
  @Input() peopleContacts: GoogleContactsInterface[] = [];
  @Input() template: Template;
  @Input() date!: string;
  @Input() priorityPainterClass!: string;
  @Input() dayEditor: boolean = true;
  @Input() googleContactsEnabled: boolean = false;
  cdkDragMove = cdkDragMove;

  activities: Activity[] = [];
  @Input() priorities: Priority[] = [];
  @Input() groupsWithTasks: AssignedGroupContainer[];
  @Input() activityStatuses: ActivityStatus[];

  constructor(
    private dataProviderService: DataproviderService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private activityManagementService: ActivityManagementService,
    private route: ActivatedRoute,
    private activitiesService: ActivitiesService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.dataProviderService.subscribeToDays({
      next: () => this.refreshData(),
    });
  }

  ngAfterViewInit() {
    this.showActivityManagementBlockIfHaveQueryParams();
  }

  refreshData() {
    let activities_tmp;
    this.dataProviderService.getActivitiesPerPhase$(this.phase)
      .pipe(untilDestroyed(this))
      .subscribe((activities) => {
        activities_tmp = activities.filter((activity) => !activity.phaseDefault);
        //this.activities = activities.filter((activity) => !activity.phaseDefault);
      });


      // Restore activities order if ordering information exists
      // else leave the order as is
      if (!this.phase.orderActivities) {
         this.activities = activities_tmp;
         return;
      }

      let activities_order = JSON.parse(this.phase.orderActivities);

      if (activities_order.length === activities_tmp.length) {

         // Clear activities array
         this.activities.length = 0;

         // Fill activities array in right order
         for (let id of activities_order) {
           for (let activity of activities_tmp) {
             if (activity.id === id) {
               this.activities.push(activity);
            }
           }
         }
         this.activities = PhaseSorter.recalculateStartEndTime(this.activities, this.phase.fixedStart);
       } else {
         this.activities = activities_tmp;
       }
  }

  showActivityManagementBlockIfHaveQueryParams() {
    const activityIdFromParams = this.route.snapshot.queryParamMap.get('activityId');

    if (!!activityIdFromParams) {
      const foundedActivityById = this.activities.find((el) => +el.id === +activityIdFromParams);

      if (!foundedActivityById) {
        return;
      }

      this.activityManagementService.setActivityData(foundedActivityById);
    }
    this.cdr.detectChanges();
  }

  addActivity() {
    const activityDuration = 15;
    this.dataProviderService.addNewActivityToPhase(
      this.phase,
      '',
      activityDuration,
      null,
    ).pipe(take(1), untilDestroyed(this))
      .subscribe(() => {
        this.notificationService.success({
          title: this.translate.instant(marker('Keep it up...')),
          description: this.translate.instant(marker('Activity added successfully')),
        });
      });
  }

  drop(event: CdkDragDrop<Activity[]>) {
    this.cdkDragMove.next(true);

    if (event.previousContainer === event.container && event.currentIndex === event.previousIndex || this.phase === undefined) {
      return;
    }

    let prevActivity = event.previousContainer.data[event.previousIndex];
    let toActivity;
    let dir: DropDirectionState = DropDirectionState.After;

    if (event.previousContainer === event.container) {
      dir = getDirectionState(event);
      prevActivity = event.container.data[event.previousIndex];
      toActivity = event.container.data[event.currentIndex];

      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if (event.currentIndex === 0) {
        const actPhaseDefault = this.phase?.activities.filter((activity) => !!activity.phaseDefault).pop();
        if (actPhaseDefault) {
          toActivity = actPhaseDefault;
        }
      } else {
        toActivity = event.container.data[event.currentIndex - 1];
      }

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }

    // Save activities order
    var activities_order = [];
    for (let data of event.container.data) {
      activities_order.push(data.id);
    }
    this.phase.orderActivities = JSON.stringify(activities_order);
    this.dataProviderService.updatePhase(this.phase)
      .pipe(take(1), untilDestroyed(this))
      .subscribe();

     // Update Start and End time for activities
     this.activities = PhaseSorter.recalculateStartEndTime(this.activities, this.phase.fixedStart);
  }

  private resetActivitiesSorting(event: CdkDragDrop<Activity[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.activities, event.currentIndex, event.previousIndex);
    } else {
      transferArrayItem(
        event.container.data,
        event.previousContainer.data,
        event.currentIndex,
        event.previousIndex,
      );
    }
  }
}
