import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { Activity, Phase, Priority } from '../../../../core/models/interfaces';
import { DataproviderService } from '../../../../core/services/dataprovider.service';
import { DateTime } from 'luxon';
import {
  NgxMaterialTimepickerTheme,
} from 'ngx-material-timepicker/src/app/material-timepicker/models/ngx-material-timepicker-theme.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { MatMenu, MatMenuTrigger, MatMenuItem } from '@angular/material/menu';

import { debounceTime, filter, finalize, take } from 'rxjs/operators';
import { NotificationService } from '../../../../core/services/notification.service';
import { ngxMaterialTimepickerTheme } from '../../../../_helpers/helpers';
import { UserLocaleService } from '../../../../shared/services/user-locale.service';
import { NgProgressService } from '../../../../shared/services/ng-progress.service';
import {
  GoogleContactsInterface,
  GoogleContactsService,
  maxContactsNumber,
} from '../../../google/services/google-contacts.service';
import { ActivityManagementService } from '../../../../core/services/activity-management.service';
import { PlanningBoardClipboardService } from '../planning-board/services/planning-board-clipboard.service';
import { DateTimeFormatPipe } from '../../../../shared/pipes/date-time-format.pipe';
import { AppButtonComponent } from '../../../../core/components/app-button/app-button.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DurationEntryComponent } from '../../../../core/components/objects/duration-entry/duration-entry.component';
import { ActivityEntryInfoOverlayComponent } from './activity-entry-info-overlay/activity-entry-info-overlay.component';
import { ActivityAssignedTaskComponent } from '../../../../core/components/objects/activity-assigned-task/activity-assigned-task.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { NgClass, NgFor, NgIf, AsyncPipe } from '@angular/common';
import { ActivityStatus, AssignedGroupContainer, AssignedGroupContainerTask } from '../planning-board/helpers/planning-board.helper';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { PhasesService } from '../../../../core/services/phases.service';
import {
  cloneActivityAction,
  deleteActivityAction,
  updateActivityAction,
  updateActivityPriorityAction,
  updateTaskActivityAction,
} from '../../../../store/activities/activities.actions';
import { ConfirmationService } from '../../../../core/services/confirmation.service';
import { addActivityToClipboardAction } from '../../../../store/clipboard-activities/clipboard-activities.actions';

const CallKeyWords = [marker('call '), marker('call to '), marker('phone '), marker('phone number ')];
const MailKeyWords = [marker('send mail to '), marker('mail '), marker('send '), marker('mail to '), marker('write '), marker('to write a letter ')];

@UntilDestroy()
@Component({
    selector: 'app-activity-list-entry',
    templateUrl: './activity-list-entry.component.html',
    standalone: true,
    imports: [
        NgClass,
        MatIcon,
        MatMenuTrigger,
        MatMenu,
        NgFor,
        ReactiveFormsModule,
        FormsModule,
        NgIf,
        ActivityAssignedTaskComponent,
        ActivityEntryInfoOverlayComponent,
        DurationEntryComponent,
        NgxMaterialTimepickerModule,
        AppButtonComponent,
        MatMenuItem,
        AsyncPipe,
        DateTimeFormatPipe,
        TranslateModule,
    ],
})
export class ActivityListEntryComponent implements AfterViewInit {
  @ViewChild('contactsMenuTrigger', {static: true}) contactsMenuTrigger: MatMenuTrigger;
  @ViewChild('contactsMenu', {static: true}) contactsMenu: MatMenu;
  @Input() activity!: Activity;

  public theme: NgxMaterialTimepickerTheme = ngxMaterialTimepickerTheme;
  public activityData$ = this._activityManagementService.activityData$;

  private _activities!: Activity[];
  @Input()
  get activitiesList(): Activity[] {
    return this._activities;
  }
  set activitiesList(activities: Activity[]) {
    this._activities = activities;
    // debugger;
    // if (!!this._newActivity) {
    //   const actIndex = this._activities.findIndex((el) => el.id === this.activity.id);
    //   moveItemInArray(this._activities, this._activities.length - 1, actIndex);

    //   // this.dataProviderService.moveActivityById(this._newActivity, this.activity, 'BEFORE')
    //   //   .pipe(take(1), untilDestroyed(this))
    //   //   .subscribe(() => {
    //   //     debugger;
    //   //     this._newActivity = null;
    //   //   });
    // }
  }


  @Input() readonly lastContacts: GoogleContactsInterface[] = [];
  @Input() googleContactsEnabled: boolean = false; // indicates whether the component is used in DayView or in TemplateView used to switch the moveToBacklog button on/off
  @Input() phase!: Phase;
  @Input() priorities: Priority[] = [];
  @Input() dayEditor: boolean = true; // indicates whether the component is used in DayView or in TemplateView used to switch the moveToBacklog button on/off
  @Input() priorityPainterClass = '';
  @Input() groupsWithTasks: AssignedGroupContainer[];
  @Input() activityStatuses: ActivityStatus[];

  public isMailKeyWord = '';
  public isCallKeyWord = '';
  public searchPeopleValue = '';

  private _newActivity: Activity;
  private _allContacts: GoogleContactsInterface[] = [];

  constructor(
    private _store: Store<AppState>,
    // private dataProviderService: DataproviderService,
    private _phasesService: PhasesService,
    private _confirmationService: ConfirmationService,
    private _notificationService: NotificationService,
    private _progressService: NgProgressService,
    private _userLocaleService: UserLocaleService,
    private _googleContactsService: GoogleContactsService,
    private _translate: TranslateService,
    private _activityManagementService: ActivityManagementService,
    private _planningBoardClipboardService: PlanningBoardClipboardService,
  ) { }

  ngAfterViewInit() {
    this.setContacts(this.lastContacts);
  }

  get priorityPainterId() {
    return this.priorities.find((el) => el.description === this.priorityPainterClass).id;
  }

  get savedContacts() {
    return this._googleContactsService.savedContacts;
  }

  get contacts(): GoogleContactsInterface[] {
    if (this.isMailKeyWord) {
      return this._allContacts.filter((el) => el.emails.length);
    }
    if (this.isCallKeyWord) {
      return this._allContacts.filter((el) => el.phones.length);
    }

    return [];
  }

  get isActiveNowActivity() {
    return this.activity.actStart < DateTime.now() && DateTime.now() < this.activity.actEnd;
  }

  get timeFormat() {
    return this._userLocaleService.format.time.includes('a') ? '12' : '24';
  }

  setContacts(contacts: GoogleContactsInterface[]) {
    this._allContacts = this.savedContacts.length >= maxContactsNumber ? this.savedContacts : contacts;
  }

  public updatePriority(activityId: number, priorityId: number): void {
    if (this.activity.priority.id === priorityId) {
      return;
    }

    this._store.dispatch(updateActivityPriorityAction({
      changes: {
        templateId: this.activity.templateId,
        date: this.activity.date,
        activityId: activityId,
        priorityId,
      },
      msg: 'Priority changed',
    }));
  }

  public durationChanged(value: number): void {
    this.activity.duration = value;
    this._onSave({ ...this.activity, duration: value });
  }

  public doneChanged(value: boolean): void {
    this.activity.done = value;
    this._onSave({ ...this.activity, done: this.activity.done });
  }

  public descriptionChanged(): void {
    this._onSave({ ...this.activity, description: this.activity.description });
  }

  public assignTaskToActivity(event: {
    task: AssignedGroupContainerTask;
    group: AssignedGroupContainer;
    oldActivity: Activity
  }): void {
    this._store.dispatch(updateTaskActivityAction({
      oldActivity: event.oldActivity,
      activity: {
        ...this.activity,
        task: {
          id: event.task.id,
          is_implicit: event.task.is_implicit,
          description: event.task.description,
          group: {
            id: event.group.id,
            title: event.group.title,
          },
        },
      },
      msg: marker('Task successfully assigned'),
    }));
  }

  public removeTaskBinding(event: { oldActivity: Activity }): void {
    this._store.dispatch(updateTaskActivityAction({
      oldActivity: event.oldActivity,
      activity: {
        ...this.activity,
        task: null,
      },
      msg: marker('Task binding successfully removed'),
    }));
  }

  public descriptionEntering(value: string): void {
    if (!this.googleContactsEnabled || !value.length || value.length < 2) return;

    this.isCallKeyWord = CallKeyWords.find((word) => value.includes(this._translate.instant(word)));
    this.isMailKeyWord = MailKeyWords.find((word) => value.includes(this._translate.instant(word)));

    if (this.isCallKeyWord || this.isMailKeyWord && this.contactsMenu.closed) {
      const keyWord = this._translate.instant(this.isCallKeyWord || this.isMailKeyWord);
      const fromValue = value.search(keyWord);
      const toValue = value.length;
      this.searchPeopleValue = value.substring(fromValue, toValue).replace(keyWord, '');
      this.contactsMenuTrigger.openMenu();

      if (!this.searchPeopleValue?.length) {
        return this.setContacts(this.lastContacts);
      }

      this._googleContactsService.searchPeople(this.searchPeopleValue)
        .pipe(take(1), debounceTime(250), untilDestroyed(this))
        .subscribe((people) => {
          this._allContacts = people;
        });
    } else {
      this.contactsMenuTrigger.closeMenu();
    }
  }

  public setActivityCallDescription(activity: Activity, contact: GoogleContactsInterface, email: string): void {
    activity.description = `${activity.description} ${contact.displayName} (${email})`.replace(this.searchPeopleValue, '');
    this.descriptionChanged();
    this.contactsMenuTrigger.closeMenu();
    this._googleContactsService.saveNewContact(contact);
  }

  public fixedStartChanged(event: Event): void {
    if (event && event.toString() !== '') {
      const format = `${this._userLocaleService.format.date} ${this._userLocaleService.format.time}`;
      this.activity.fixedStart = DateTime.fromFormat(this.activity.date.toFormat(this._userLocaleService.format.date) + ' ' + event.toString(), format);
    } else {
      this.activity.fixedStart = undefined;
    }

    this._onSave(this.activity);
  }

  public clearFixedStartChanged(): void {
    this.activity.fixedStart = undefined;
    this._onSave(this.activity);
  }

  public deleteActivity(): void {
    this._confirmationService.openDialog()
      .pipe(filter((confirmed) => confirmed))
      .subscribe(() => {
        this._store.dispatch(deleteActivityAction({
          activity: this.activity,
          msg: marker('Successfully deleted activity'),
        }));
      });
  }

  public cloneActivity(): void {
    this._store.dispatch(cloneActivityAction({
      activity: this.activity,
      msg: marker('Activity successfully cloned'),
    }));
  }

  private _onSave(updates: Activity): void {
    this._store.dispatch(updateActivityAction({
      activity: updates,
      msg: marker('Successfully changed'),
    }));
  }

  // TODO: what does toSomeOrder means?
  public addActivity(toSomeOrder: boolean): void {
    const activityDuration = 15; // FIXME: bad to use - need global values or settings

    this._progressService.start();
    this._phasesService.addNewActivityToPhase(this.phase, '', activityDuration, null)
      .pipe(
        take(1),
        finalize(() => this._progressService.complete()),
        untilDestroyed(this),
      )
      .subscribe({
        next: (response) => {
          if (toSomeOrder) {
            this._newActivity = response.data.newActivityToPhase.activity;
          }
          this._notificationService.success({
            title: this._translate.instant(marker('Plus one a day')),
            description: this._translate.instant(marker('Activity added successfully')),
          });
        },
        error: (error) => {
          this._notificationService.error({
             title: this._translate.instant(marker('Failure to adding...')),
            description: error,
          });
        },
       });
  }

  public moveToClipboard(): void {
    if (this.activity.in_clipboard) {
      return;
    }

    this._store.dispatch(addActivityToClipboardAction({
      activity: this._planningBoardClipboardService.addClipboardActivity(this.activity),
      msg: marker('The activity has been successfully added to the clipboard'),
    }));

    if (!this._planningBoardClipboardService.clipboardVisible$.getValue()) {
      this._planningBoardClipboardService.toggleVisibility(true);
    }

    // TODO: this is for new activity list handling:
    // ---------------------------------
    // order_in_phase set to null
    // activity.update!(in_clipboard: true, previous_activity: nil, next_activity: nil, done: nil, phase_id: nil, date: nil)
    // new graphql query - moveActivityToClipboard
  }
}
