<div class="c-planningBoardClipboard__wrapper" [class.visible]="clipboardVisible$ | async" [style.width]="clipboardSize">
  <div class="c-planningBoardClipboard__sizer" (mousedown)="changeClipboardSize()"></div>

  <ng-content select="[navigation]"></ng-content>

  <div class="c-planningBoardClipboard">
    <div class="c-planningBoardClipboard__header">
      <p class="c-planningBoardClipboard__title">{{'Clipboard' | translate}}</p>
      <p class="c-planningBoardClipboard__subtitle">
        {{'Drag the activities you want here to move them to my day' | translate}}
      </p>
    </div>

    <div class="c-planningBoardClipboard__body">
      <ul class="c-activityList" [cdkDropListData]="activities" (cdkDropListDropped)="drop($event)" cdkDropList>
        <ng-container *ngIf="activities.length else activitiesEmpty">
          <ng-container *ngFor="let activity of activities">
            <planning-board-activity
              [parent]="'clipboard'"
              [groupsWithTasks]="groupsWithTasks"
              [activity]="activity"
              [activityStatuses]="activityStatuses"
              [priorities]="priorities"
              (cloneActivity)="cloneActivity(activity)"></planning-board-activity>
          </ng-container>
        </ng-container>
        <ng-template #activitiesEmpty>
          <li class="text-gray px-6">{{'You have no activities on the clipboard' | translate}}</li>
        </ng-template>
      </ul>
    </div>
  </div>

  <button class="c-planningBoardClipboard__trigger" (click)="toggleClipboardVisibility()">
    <span class="c-planningBoardClipboard__triggerText">{{'Clipboard' | translate}}</span>
    <mat-icon>{{(clipboardVisible$ | async) ? 'chevron_right' : 'chevron_left'}}</mat-icon>
  </button>

</div>
