import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Activity, Phase, Priority } from '../../../../core/models/interfaces';
import { DateTime } from 'luxon';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationService } from '../../../../core/services/notification.service';
import { skip, take } from 'rxjs/operators';
import { BehaviorSubject, interval } from 'rxjs';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker/src/app/material-timepicker/models/ngx-material-timepicker-theme.interface';
import {
  cdkDragMove,
  ngxMaterialTimepickerTheme,
} from '../../../../_helpers/helpers';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader } from '@angular/material/expansion';
import { UserLocaleService } from '../../../../shared/services/user-locale.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { GoogleContactsInterface, GoogleContactsService } from '../../../google/services/google-contacts.service';
import { DateTimeFormatPipe } from '../../../../shared/pipes/date-time-format.pipe';
import { ActivityListComponent } from '../activity-list/activity-list.component';
import { TimeProgressComponent } from './time-progress/time-progress.component';
import { MatIconButton } from '@angular/material/button';
import { AppButtonComponent } from '../../../../core/components/app-button/app-button.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { GroupContainersService } from '../planning-board/services/group-containers.service';
import { ActivityStatus, AssignedGroupContainer } from '../planning-board/helpers/planning-board.helper';
import { PhasesService } from '../../../../core/services/phases.service';

const showedNotificationsStorageKey = 'showed-notifications';

const showedNotificationsObjectDefaultValue: ShowedNotificationsObject = {
  date: null,
  notifications: [],
};

interface ShowedNotificationsObject {
  date: number;
  notifications: string[];
}

@UntilDestroy()
@Component({
    selector: 'app-phase-list',
    templateUrl: './phase-list.component.html',
    styleUrls: ['./phase-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        MatAccordion,
        NgFor,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        NgClass,
        MatIcon,
        ReactiveFormsModule,
        FormsModule,
        NgxMaterialTimepickerModule,
        AppButtonComponent,
        MatIconButton,
        TimeProgressComponent,
        ActivityListComponent,
        DateTimeFormatPipe,
        TranslateModule,
    ],
})
export class PhaseListComponent implements OnInit {
  @ViewChild('mat') matAccordion: MatAccordion;

  @Input() changePriorities$ = new BehaviorSubject<boolean>(false);
  @Input() date!: string;
  @Input() dayEditor: boolean = true;
  @Input() priorities: Priority[] = [];
  @Input() activityStatuses: ActivityStatus[] = [];

  private _priorityPainterIndex = 0;
  @Input()
  get priorityPainterIndex(): number {
    return this._priorityPainterIndex;
  }
  set priorityPainterIndex(index: number) {
    this._priorityPainterIndex = index;
    this._changePriorities(this._priorityPainterIndex);
  }

  @Input()
  get phasesList(): Phase[] {
    return this.phases;
  }
  set phasesList(phases: Phase[]) {
    this.phases = phases;
    this._getCurrentTimePhase();
    this._deleteShowedNotificationsFromStorage();
    this._showNotificationAboutNearestActivity();
  }

  @Output() priorityPainterDataEmitter$ = new EventEmitter();

  public phases: Phase[];
  public timepickerTheme: NgxMaterialTimepickerTheme = ngxMaterialTimepickerTheme;
  public peopleContacts: GoogleContactsInterface[] = [];
  public googleContactsEnabled: boolean = false;
  public priorityPainter = null;
  public priorityPainterClass = '';
  public groupsWithTasks: AssignedGroupContainer[] = [];

  private _showEditPhase: Map<number, boolean> = new Map(); // a map, which indicate whether the editing for phase name is active
  private _showedNotificationsObject: ShowedNotificationsObject = JSON.parse(localStorage.getItem(showedNotificationsStorageKey) || 'null') || showedNotificationsObjectDefaultValue;
  private _lastPastPhase: { fixedStart: DateTime, name: string } = null;

  constructor(
    private _phasesService: PhasesService,
    private _notificationService: NotificationService,
    private _userLocaleService: UserLocaleService,
    private _translate: TranslateService,
    private _googleContactsService: GoogleContactsService,
    private _groupContainersService: GroupContainersService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this._showEditPhase = new Map();

    this.phases.forEach((phase) => this._showEditPhase.set(phase.id, false));
    const timeForInterval = 60000;

    interval(timeForInterval)
      .pipe(untilDestroyed(this))
      .subscribe(() => this._showNotificationAboutNearestActivity());

    this._getPriorities();

    this._googleContactsService.getAllPeople()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((contacts) => this.peopleContacts = contacts);

    this._googleContactsService.googleContactsEnabled()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((enabled) => this.googleContactsEnabled = enabled);

    this._fetchGroupsWithTask();

    this._cdkDragMoving();
  }

  get timeFormat(): string {
    return this._userLocaleService.format.time.includes('a') ? '12' : '24';
  }

  public nextPhase(phaseId: number): Phase | undefined {
    return this.phases[this.phases.findIndex((el) => el.id === phaseId) + 1];
  }

  public isAllActivitiesDone(phase: Phase): boolean {
    return phase.activities?.filter((act) => !act.description?.includes('default')).every((act) => act.done === true);
  }

  public fixedStartChanged(phase: Phase, event: string): void {
    phase.fixedStart = this._prepareFixedStartTime(event);
  }

  public deletePhase(phase: Phase): void {
    this._phasesService.deletePhase(phase);
  }

  public editPhase(id: number): boolean {
    return this._showEditPhase.get(id);
  }

  public enableEditPhase(phase: Phase): void {
    this._lastPastPhase = {
      fixedStart: phase.fixedStart,
      name: phase.name,
    };
    this._showEditPhase.set(phase.id, true);
  }

  public saveUpdatedPhase(phase: Phase): void {
    this._showEditPhase.set(phase.id, false);
    this._updatePhase(phase, this._translate.instant(marker('Phase is updated')));
  }

  public cancelUpdatedPhase(phase: Phase): void {
    this._showEditPhase.set(phase.id, false);
    phase.name = this._lastPastPhase.name;
    phase.fixedStart = this._lastPastPhase.fixedStart;
  }

  private _cdkDragMoving(): void {
    cdkDragMove.pipe(untilDestroyed(this)).subscribe((dropped: boolean) => {
      const openPhaseMouseover = (event) => {
        if (event.target.classList.contains('mat-expansion-panel')) {
          this.matAccordion._headers.forEach((item) => {
            if (event.target.querySelector('mat-expansion-panel-header.mat-expansion-panel-header').id === item.panel._headerId) {
              item.panel.open();
            }
          });
        }
      };
      // @ts-ignore
      dropped ? document.removeAllListeners() : document.addEventListener('mousemove', openPhaseMouseover, false);
    });
  }

  private _prepareFixedStartTime(event: string): DateTime {
    const two = 2;
    const dd = String(new Date().getDate()).padStart(two, '0');
    const mm = String(new Date().getMonth() + 1).padStart(two, '0');
    const yyyy = new Date().getFullYear();
    const today = mm + '/' + dd + '/' + yyyy;
    return DateTime.fromJSDate(new Date(today + ' ' + event));
  }

  private _getCurrentTimePhase(): void {
    if (this.phases.length) {
      this.phases.forEach((phase) => {
        this._isCurrentTimePhase(phase);
      });
      setTimeout(() => {
        const isCurrentTimeElement = document.querySelector('.isCurrentTime');
        if (isCurrentTimeElement) {
          isCurrentTimeElement.scrollIntoView({block: 'center'});
        }
      }, 0);
    }
  }

  private _isCurrentTimePhase(phase: Phase): void {
    const nowTime = new Date();
    const fixedStart = new Date(
      nowTime.getFullYear(),
      nowTime.getMonth(),
      nowTime.getDate(),
      phase.fixedStart.hour,
      phase.fixedStart.minute,
    );

    const lastHourInDayDate = DateTime.fromISO(this.date).set({hour: 23, minute: 59, second: 59, millisecond: 59});
    const nextPhaseTime = !!this.nextPhase(phase.id) ? this.nextPhase(phase.id).fixedStart : lastHourInDayDate;

    const fixedEnd = new Date(
      nowTime.getFullYear(),
      nowTime.getMonth(),
      nowTime.getDate(),
      nextPhaseTime.hour,
      nextPhaseTime.minute,
    );
    setTimeout(() => phase.isCurrentTime = fixedStart < nowTime && nowTime < fixedEnd, 0);
  }

  private _changePriorities(priorityPainterIndex: number): void {
    if (!this.priorityPainter) {
      return;
    }

    this.priorityPainter.style.display = 'flex';

    const moveCursor = (e) => {
      const mouseY = e.clientY;
      const mouseX = e.clientX;
      this.priorityPainter.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0)`;
    };

    window.addEventListener('mousemove', moveCursor);

    this.priorityPainterClass = this.priorities[priorityPainterIndex].description;

    document.addEventListener('keyup', (event) => {
      if (event.key === 'Escape') {
        return this.changePriorities$.next(false);
      }

      if (event.key !== 'Enter') {
        return;
      }

      priorityPainterIndex = priorityPainterIndex !== this.priorities.length - 1 ? priorityPainterIndex + 1 : 0;
      this.priorityPainterDataEmitter$.emit({
        enable: true,
        priorityPainterIndex: priorityPainterIndex,
      });
      this.priorityPainterClass = this.priorities[priorityPainterIndex].description;


      event.preventDefault();
    });
  }

  private _showNotificationAboutNearestActivity(): void {
    this.phases.forEach((phase) => {
      const activityBeginSoon = phase.activities.filter((act) => !act.description.includes('default') && !act.done)
        .find((act) => {
          const activityStartTime = new Date(act.actStart.toString());
          const activityNotificationNotShowed = !this._showedNotificationsObject?.notifications.some((el) => el === PhaseListComponent._preparedActivityNotificationId(act));
          return new Date(activityStartTime.setMinutes(activityStartTime.getMinutes() - 10)) < new Date() && new Date() < new Date(act.actStart.toString()) && activityNotificationNotShowed;
        });

      if (activityBeginSoon) {
        PhaseListComponent._updateShowedNotifications(this._showedNotificationsObject, PhaseListComponent._preparedActivityNotificationId(activityBeginSoon));
        this._notificationService.primary(
          {
            title: this._translate.instant(marker('Reminder...')),
            description: `${this._translate.instant(marker('Activity'))} "${activityBeginSoon.description}" ${this._translate.instant(marker('will start at'))} ${activityBeginSoon.actStart.toFormat(this._userLocaleService.format.time)}`,
          },
          {
            icon: '⏰',
            autoClose: false,
          },
        );
        PhaseListComponent._playReminderSound();
      }
    });
  }

  private static _playReminderSound(): void {
    let audio = new Audio();
    audio.src = '/assets/sounds/reminder.mp3';
    audio.load();
    audio.play();
  }

  private static _preparedActivityNotificationId(activity: Activity): string {
    return `${activity.id}-${activity.actStart.toFormat('HH:mm')}`;
  }

  private static _updateShowedNotifications(showedNotifications: ShowedNotificationsObject, activityBeginSoonTime: string): void {
    showedNotifications.notifications.push(activityBeginSoonTime);
    const requestBody: ShowedNotificationsObject = {
      date: new Date().getDate(),
      notifications: showedNotifications.notifications,
    };
    localStorage.setItem(showedNotificationsStorageKey, JSON.stringify(requestBody));
  }

  private _deleteShowedNotificationsFromStorage(): void {
    if (!!this._showedNotificationsObject.date && this._showedNotificationsObject.date !== new Date().getDate()) {
      localStorage.removeItem(showedNotificationsStorageKey);
    }
  }

  private _fetchGroupsWithTask(): void {
    this._groupContainersService.fetchActiveGroupsWithActiveTasks()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((groups) => {
        this.groupsWithTasks = groups;
      });
  }

  private _getPriorities(): void {
    this.priorityPainter = document.getElementById('priorityPainter');

    this.changePriorities$
      .pipe(skip(1), untilDestroyed(this))
      .subscribe((enable) => {
        this.priorityPainterDataEmitter$.emit({
          enable,
          priorityPainterIndex: this._priorityPainterIndex,
        });
        if (enable) {
          this._notificationService.primary({
            description: this._translate.instant(marker('Press the Enter key to change the priority')),
          });
          this._changePriorities(this._priorityPainterIndex);
        } else {
          this._closeChangingPriorities();
        }
      });
  }

  private _closeChangingPriorities(): void {
    this.priorityPainter.style.display = 'none';
    this.priorityPainterClass = '';
    window.removeAllListeners();
  }

  private _updatePhase(phase: Phase, notificationMsg): void {
    this._phasesService.updatePhase(phase)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(() => {
        this._notificationService.success({
          title: this._translate.instant(marker('Nicely...')),
          description: notificationMsg,
        });
        this._showEditPhase.set(phase.id, false);
      });
  }
}
