import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from '../../../../../core/models/interfaces';
import { ActivityManagementService } from '../../../../../core/services/activity-management.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { DateTimeFormatPipe } from '../../../../../shared/pipes/date-time-format.pipe';
import { NgIf } from '@angular/common';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'activity-entry-info-overlay',
    templateUrl: './activity-entry-info-overlay.component.html',
    styleUrls: ['./activity-entry-info-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        MatIcon,
        MatMenuTrigger,
        MatMenu,
        NgIf,
        DateTimeFormatPipe,
        TranslateModule,
    ],
})
export class ActivityEntryInfoOverlayComponent implements OnInit {
  @Input() activity!: Activity;

  constructor(
    private activityManagementService: ActivityManagementService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
  }

  get note() {
    return this.sanitizer.bypassSecurityTrustHtml(this.activity.note);
  }

  openMoreInfo() {
    this.activityManagementService.setActivityData(this.activity);
  }
}
