import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { Phase, Priority, Template } from 'src/app/core/models/interfaces';
import { DataproviderService } from 'src/app/core/services/dataprovider.service';
import { TEMPLATE_DATE } from '../../../../_helpers/constants.helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, switchMap, take } from 'rxjs/operators';
import { NotificationService } from '../../../../core/services/notification.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AppSettingsService } from '../../../../core/services/app-settings.service';
import { MatDivider } from '@angular/material/divider';
import { MatList, MatListItem } from '@angular/material/list';
import { MatOption, MatRipple } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';
import { AppButtonComponent } from '../../../../core/components/app-button/app-button.component';
import { MatIcon } from '@angular/material/icon';
import { PhaseListComponent } from '../phase-list/phase-list.component';
import { CdkDropListGroup } from '@angular/cdk/drag-drop';
import { AddPhaseDialog } from '../day-editor/components/add-phase-dialog/add-phase-dialog.component';
import { EditTemplateDialog } from './components/edit-template-dialog/edit-template-dialog.component';
import { ActivityStatus } from '../planning-board/helpers/planning-board.helper';

@UntilDestroy()
@Component({
    templateUrl: './template-editor.component.html',
    styleUrls: ['./template-editor.component.scss'],
    standalone: true,
    imports: [
        CdkDropListGroup,
        PhaseListComponent,
        MatIcon,
        AppButtonComponent,
        NgIf,
        MatFormField,
        MatLabel,
        MatSelect,
        NgFor,
        MatOption,
        MatList,
        MatListItem,
        MatRipple,
        MatDivider,
        TranslateModule,
    ],
})
export class TemplateEditorComponent implements OnInit {
  templates: Template[];
  phases: Phase[] = [];
  activityStatuses: ActivityStatus[] = [];
  priorities: Priority[] = [];

  date: string = TEMPLATE_DATE;

  selectedTemplate: Template;

  deleteOn: boolean = false;

  constructor(
    private dataProviderService: DataproviderService,
    private translate: TranslateService,
    private appSettingsService: AppSettingsService,
    public addPhaseDialog: MatDialog,
    public editTemplateDialog: MatDialog,
    private _notificationService: NotificationService,
  ) {
  }

  ngOnInit(): void {
    this.dataProviderService.subscribeToTemplates({
      next: (templates) => this.templates = templates,
    });

    this.dataProviderService.subscribeToDays({
      next: (days) => this.refreshPhases(),
    });

    this.appSettingsService.subscribeToPriorities({
      next: (priorities) => {
        this.priorities = priorities;
      },
    });
  }

  refreshPhases() {
    if (this.selectedTemplate !== undefined) {
      this.phases = this.dataProviderService.getDayPhases(this.date, this.selectedTemplate.id);
    }
  }

  selectionChanged(event: MatSelectChange) {
    this.changeTemplate();
  }

  private changeTemplate() {
    this.dataProviderService.changeTemplate(this.date, this.selectedTemplate.id);
    this.deleteOn = !this.selectedTemplate.default;
  }

  editTemplate() {
    // opens a modal dialog to edit the template attributes
    if (this.selectedTemplate !== undefined) {
      const dialogRef = this.editTemplateDialog.open(EditTemplateDialog, {
        data: {template: this.selectedTemplate},
      });
      dialogRef.afterClosed()
        .pipe(
          take(1),
          filter((template: Template) => !!template),
          switchMap((template: Template) => this.dataProviderService.updateTemplate(template.id, template.name, template.active)),
          untilDestroyed(this),
        ).subscribe(() => {
        this.selectedTemplate = this.templates.find((tpl) => tpl.id === this.selectedTemplate.id);
      });
    }
  }

  createTemplate() {
    const dialogRef = this.editTemplateDialog.open(EditTemplateDialog, {
      data: {template: undefined},
    });
    dialogRef.afterClosed().pipe(
      take(1),
      filter((template) => !!template),
      switchMap((template: Template) => this.dataProviderService.createTemplate(template.name, template.active)),
      untilDestroyed(this),
    ).subscribe((result) => {
      this.selectedTemplate = result.data.createTemplate.template;
      this.changeTemplate();
    });
  }

  cloneTemplate() {
    if (!!this.selectedTemplate) {
      const dialogRef = this.editTemplateDialog.open(EditTemplateDialog, {
        data: {template: undefined},
      });
      dialogRef.afterClosed().pipe(
        take(1),
        filter((template) => !!template),
        switchMap((template: Template) => this.dataProviderService.cloneTemplate(this.selectedTemplate.id, template.name)),
        untilDestroyed(this),
      ).subscribe((result) => {
        this.selectedTemplate = result.data.cloneTemplate.template;
        this.changeTemplate();
      });
    }
  }

  deleteTemplate() {
    this.dataProviderService.deleteTemplate(this.selectedTemplate.id)
      .pipe(take(1), untilDestroyed(this)).subscribe(() => {
      this.selectedTemplate = undefined;
      this._notificationService.success({
         title: this.translate.instant(marker('Super...')),
         description: this.translate.instant(marker('Successfully deleted template')),
        },
      );
    }, (error) => {
      this._notificationService.error({
        title: this.translate.instant(marker('Oops...')),
        description: this.translate.instant(marker('Failed to delete template')),
      });
    });
  }

  openPhaseAddDialog() {
    if (!!this.selectedTemplate) {
      this.addPhaseDialog.open(AddPhaseDialog, {
        data: {date: this.date, templateId: this.selectedTemplate.id},
      });
    }
  }

}
