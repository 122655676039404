// Date
export const NO_CONTENT_HTTP_CODE = 204;
export const SEC_IN_MINUTE = 60;
export const MLS_IN_SECOND = 1000;
export const PASSWORD_MIN_LENGTH = 6;
export const TEMPLATE_DATE = '1900-05-21';

// Keycloak
export const LOGIN_URL = 'realms/plan-my-day/protocol/openid-connect/auth?client_id=pmd-user&scope=openid&response_type=code&';

